import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'

import MIterationsPicker from '@/components/molecules/project-details/MIterationsPicker'
import OIterationDetails from '@/components/organisms/project-details/OIterationDetails/OIterationDetails.jsx'

import useStore from '@/stores/useStore'
import IterationHeader from '@/components/molecules/project-details/IterationHeader.jsx'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid/index.js'
import classNames from 'classnames'
import { Badge } from '@/components/catalyst/badge.jsx'
import { DateTime } from 'luxon'
import { FaceFrownIcon } from '@heroicons/react/24/outline/index.js'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'

export default function OIterationsListAndDetails({
  organization,
  team,
  project,
  iterations,
  iterationsLoading,
  lastTick,
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  // needed by the iteration picker
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')

  const setActiveIterationStatus = useStore(state => state.setActiveIterationStatus)

  const iterationId = useMemo(() => {
    return searchParams.get('iteration')
  }, [searchParams])

  const [activeIterationId, setActiveIterationId] = useState(iterationId)

  // Set active iteration id from URL
  useEffect(() => {
    setActiveIterationId(iterationId)
  }, [iterationId])

  const filteredIterations = useMemo(() => {
    return iterations?.filter(iteration => {
      return (
        iteration?.id?.toLowerCase().includes(query.toLowerCase()) ||
        iteration?.prompt?.toLowerCase().includes(query.toLowerCase()) ||
        iteration?.createdBy?.toLowerCase().includes(query.toLowerCase())
      )
    })
  }, [query, iterations])

  const iterationsGroupedByDate = useMemo(() => {
    const grouped = filteredIterations?.reduce((acc, iteration) => {
      const date = DateTime.fromJSDate(iteration?.createdAt?.toDate()).toISODate()
      acc[date] = acc[date] || []
      acc[date].push(iteration)
      return acc
    }, {})

    return Object.fromEntries(Object.entries(grouped).sort(([a], [b]) => b.localeCompare(a)))
  }, [filteredIterations])

  useEffect(() => {
    const down = e => {
      if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen(open => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  const activeIterationMeta = useMemo(() => {
    if (!activeIterationId || !iterations) {
      return null
    }
    return iterations.find(iteration => iteration.id === activeIterationId)
  }, [activeIterationId, iterations])

  const handleActiveIterationSelected = useCallback(
    selectedIterationId => {
      setSearchParams({ iteration: selectedIterationId }, { replace: true })
      setActiveIterationId(selectedIterationId)
      setOpen(false)
    },
    [setSearchParams]
  )

  useEffect(() => {
    if (!activeIterationId && iterations?.length > 0) {
      handleActiveIterationSelected(iterations?.[0]?.id)
    }
  }, [iterations, activeIterationId, handleActiveIterationSelected])

  useEffect(() => {
    if (activeIterationMeta && activeIterationMeta?.status) {
      setActiveIterationStatus(activeIterationMeta.status)
    } else {
      setActiveIterationStatus(null)
    }
    return () => setActiveIterationStatus(null)
  }, [activeIterationMeta, setActiveIterationStatus])

  const isActiveIterationLatestIteration = useMemo(() => {
    if (iterations?.length > 0) {
      return activeIterationId === iterations?.[0]?.id
    }
    return false
  }, [iterations, activeIterationId])

  const handleIterationCreated = useCallback(
    data => {
      handleActiveIterationSelected(data.iterationId)
    },
    [handleActiveIterationSelected]
  )

  const getUrlFromText = useCallback(text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const urls = text.match(urlRegex)
    return urls
  }, [])

  return (
    <>
      <IterationHeader
        project={project}
        team={team}
        organization={organization}
        iterations={iterations}
        isLoading={iterationsLoading}
        activeIterationId={activeIterationId}
        onActiveSelected={handleActiveIterationSelected}
        onIterationCreated={handleIterationCreated}
      />
      {!!activeIterationMeta && (
        <OIterationDetails
          iterationMeta={activeIterationMeta}
          isNewestIteration={isActiveIterationLatestIteration}
          lastTick={lastTick}
          onIterationCreated={handleIterationCreated}
        />
      )}
      <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox
                  onChange={item => {
                    handleActiveIterationSelected(item.id)
                  }}
                >
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 size-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search by iteration id, creator id or part of prompt..."
                      onChange={event => setQuery(event.target.value)}
                      autoFocus
                    />
                  </div>

                  {filteredIterations.length > 0 && (
                    <Combobox.Options
                      static
                      className="max-h-[720px] scroll-pb-2 scroll-pt-11 space-y-2 overflow-y-auto pb-2"
                    >
                      {Object.entries(iterationsGroupedByDate).map(([category, items]) => (
                        <li key={category}>
                          <h2 className="bg-gray-100 px-4 py-2 font-mono text-xs font-semibold text-gray-900">
                            {category}
                          </h2>
                          <ul className="text-sm text-gray-800">
                            {items.map(item => (
                              <Combobox.Option
                                key={item.id}
                                value={item}
                                className={({ active }) =>
                                  classNames(
                                    'cursor-default select-none border-b border-zinc-200 px-4 text-base',
                                    active && 'bg-zinc-100',
                                    activeIterationId === item.id && 'bg-zinc-800  text-zinc-50'
                                  )
                                }
                              >
                                <div className="flex flex-col px-4 py-6">
                                  <div className="flex items-start justify-between">
                                    <div className="flex flex-col ">
                                      <span className="font-mono text-lg font-semibold tracking-tight">
                                        {item.id}
                                      </span>
                                      <span className="mt-2 font-mono text-sm text-gray-600">
                                        {getUrlFromText(item.prompt)?.map((url, index) => (
                                          <span
                                            key={index}
                                            className={classNames(
                                              activeIterationId === item.id
                                                ? 'text-zinc-50'
                                                : 'text-blue-500'
                                            )}
                                          >
                                            {url}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                      <Badge
                                        color={activeIterationId === item.id ? 'white' : 'zinc'}
                                      >
                                        {item.status}
                                      </Badge>
                                    </div>
                                  </div>
                                  <table
                                    className={classNames(
                                      'mt-4 font-mono text-xs',
                                      activeIterationId === item.id
                                        ? 'text-zinc-50'
                                        : 'text-zinc-700'
                                    )}
                                  >
                                    <tr className="h-6">
                                      <td className="w-28">Created by </td>{' '}
                                      <td className="font-bold">{item.createdBy || 'N/A'}</td>
                                    </tr>
                                    <tr className="h-6">
                                      <td className="w-28">Created on </td>
                                      <td className="font-bold">
                                        {DateTime.fromJSDate(item.createdAt.toDate()).toFormat(
                                          'LLL d, yyyy h:mm a'
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="h-6">
                                      <td className="w-28">Last activity </td>
                                      <td className="font-bold">
                                        {DateTime.fromJSDate(item.updatedAt.toDate()).toFormat(
                                          'LLL d, yyyy h:mm a'
                                        )}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </Combobox.Option>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </Combobox.Options>
                  )}

                  {query !== '' && filteredIterations.length === 0 && (
                    <div className="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14">
                      <FaceFrownIcon className="mx-auto size-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">No iterations found</p>
                      <p className="mt-2 text-gray-500">
                        Search tries to match the iteration ID within this project. Try searching
                        for a different ID.
                      </p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

OIterationsListAndDetails.propTypes = {
  iterations: PropTypes.array,
  iterationsLoading: PropTypes.bool,
  lastTick: PropTypes.number,
}
