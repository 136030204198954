import { Dialog } from '@/components/catalyst/dialog.jsx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  createIterationForProject,
  createProjectFirebaseFunction,
  getIterationDefault,
} from '@/services/Firebase.ts'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import { useToast } from '@/components/ui/use-toast.js'
import PropTypes from 'prop-types'
import { stackTemplate, technologiesOptions } from '@/const/stackTemplates.ts'
import { Check, Container, FileText, FolderOpen, MoveLeft } from 'lucide-react'
import { SiGithub } from '@icons-pack/react-simple-icons'
import { Label } from '@/components/ui/label'
import { Controller, useForm } from 'react-hook-form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { useHelpContent } from '@/components/molecules/project-details/CreateProject/useHelpContent'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cx } from 'class-variance-authority'
import { zodResolver } from '@hookform/resolvers/zod'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { Textarea } from '@/components/ui/textarea'
import { useNavigate } from 'react-router-dom'
import {
  ProjectData,
  projectSchema,
  RepositoryData,
  repositorySchema,
} from '@/components/molecules/project-details/CreateProject/schemas'
import { constructPrompt } from '@/components/molecules/project-details/CreateProject/constructPrompt'
import { useAnalystEnvFields } from './useAnalystEnvFields.ts'
import { Checkbox } from '@/components/catalyst/checkbox.jsx'

const START_GUNSLINGER_FOR_NEW_ITERATION = window?.location?.host?.startsWith('old.') ? false : true

export default function MDuplicateProjectDialog({
  projectToDuplicate,
  isOpen,
  onClose,
  onProjectCreated = () => {
    console.log('Created PoC')
  },
}: {
  projectToDuplicate: any
  organizationId?: string
  teamId?: string
  isOpen: boolean
  onClose: () => void
  onProjectCreated: () => void
}) {
  const { organizationId, teamId } = projectToDuplicate
  const projectConfiguration = projectToDuplicate?.projectConfiguration
  const [iterationDefault, setIterationDefault] = useState(null)
  const [isIterationDefaultLoading, setIsIterationDefaultLoading] = useState(true)
  const adminFields = useAnalystEnvFields()

  const configurationTemplates = useStore(state => state.configurationTemplates)

  const { toast } = useToast()

  const {
    response,
    createFn,
    error: creationError,
    isCreating,
    resetCreateFn,
  } = useDuplicateProject()

  useEffect(() => {
    if (projectToDuplicate?.id) {
      setIsIterationDefaultLoading(true)
      getIterationDefault({ projectId: projectToDuplicate.id })
        .then(iterationData => {
          setIterationDefault(iterationData.data)
          setIsIterationDefaultLoading(false)
        })
        .catch(err => {
          console.error('Error getting iteration', err)
          setIsIterationDefaultLoading(false)
          toast({
            variant: 'destructive',
            title: 'Error loading iteration defaults for project 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        })
    }
  }, [projectToDuplicate?.id])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    if (response) {
      toast({
        title: 'Project created successfully! 🎉',
      })
      onProjectCreated(response?.data || {})
      resetCreateFn()
      handleClose()
    }
    if (creationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating project 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating project:', creationError)
      resetCreateFn()
    }
  }, [response, creationError, toast, resetCreateFn, handleClose, onProjectCreated])

  const onSubmit = (projectData, configurationTemplate, newPrompt) => {
    const configurationTemplateFields = [
      ...adminFields,
      ...(configurationTemplate?.iterationDefaultsTemplate?.environment?.fields ?? []),
    ]
    const payload = {
      prompt: newPrompt,
      organizationId: projectData.organizationId,
      teamId: projectData.teamId,
      usecaseFields: configurationTemplate?.iterationDefaultsTemplate?.usecase?.fields,
      formData: {
        name: projectData.project.name,
        projectConfiguration: projectData.project,
        repository: Object.entries(projectData.repository).map(([key, value]) => ({ key, value })),
        environment: Object.entries(projectData.environment).map(([key, value]) => {
          const field = configurationTemplateFields.find(f => f.key === key)
          const dontEncrypt = field?.dontEncrypt
          return { key, value, dontEncrypt }
        }),
      },
      iterationDefault,
    }
    if (!isCreating) {
      createFn(payload)
    }
  }

  const loading = !configurationTemplates || isIterationDefaultLoading

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white/80">
        <div className="size-8 animate-spin rounded-full border-4 border-gray-200 border-t-blue-600" />
      </div>
    )
  }

  return (
    <>
      <Dialog
        className="max-h-[90vh] overflow-hidden p-[0] "
        size="4xl"
        open={isOpen}
        onClose={onClose}
        static={true}
        preventOutsideClick={true}
      >
        {loading ? (
          <div>Loading...</div>
        ) : (
          <MDuplicateProjectWizard
            onCreateProject={onSubmit}
            submittingInProgress={isCreating}
            configurationTemplates={configurationTemplates}
            projectToDuplicate={projectToDuplicate}
            iterationDefault={iterationDefault}
            organizationId={organizationId}
            teamId={teamId}
            handleCancel={handleClose}
          />
        )}
      </Dialog>
    </>
  )
}

MDuplicateProjectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProjectCreated: PropTypes.func,
}

function ProjectWizardTab({
  name,
  value,
  activeStep,
  isValid,
  Icon,
}: {
  name: string
  value: string
  activeStep: string
  isValid: boolean
  Icon: any
}) {
  const selected = activeStep === value
  return (
    <TabsTrigger
      value={value}
      disabled={activeStep !== value && !isValid}
      className={cx(
        // Base classes
        'inline-flex h-10 w-full cursor-default items-center justify-between',
        'px-4 py-2.5',

        // Conditional background
        selected ? 'bg-stone-200 text-stone-900' : 'bg-transparent'
      )}
    >
      {/*<div className="inline-flex h-10 w-full items-center justify-start gap-3 self-stretch rounded-lg px-4 py-2.5">*/}
      <div className="flex items-center justify-start gap-3">
        <div className="relative flex size-4 items-center justify-center">
          <Icon className={cx(selected ? 'text-orange-500' : 'text-stone-500')} />
        </div>
        <div className=" shrink grow basis-0 font-['Inter'] text-sm font-medium leading-tight ">
          {name}
        </div>
      </div>

      <div className="relative flex size-4 items-center justify-center">{isValid && <Check />}</div>
      {/*</div>*/}
    </TabsTrigger>
  )
}

export type EnvironmentData = {
  envType: string
  configuration: string
}

export type WizardData = {
  project: ProjectData | null
  repository: RepositoryData | null
  environment: EnvironmentData | null
}

export type WizardStep = 'project' | 'repository' | 'environment' | 'summary'

export type CompletedSteps = {
  project: boolean
  repository: boolean
  environment: boolean
  summary: boolean
}

export type ProjectFormProps = {
  onSubmit: (data: ProjectData) => void
  initialData?: Partial<ProjectData>
  onSelectField: (field: string) => void
  organizationId: string
  teamId: string
  handleCancel: () => void
}

interface RepositoryFormProps {
  onSubmit: (data: RepositoryData) => void
  onBack: () => void // New prop for handling back navigation
  initialData?: Partial<RepositoryData>
  onSelectField?: (fieldName: keyof RepositoryData) => void
  handleCancel: () => void
}

export type EnvironmentFormProps = {
  onSubmit: (data: EnvironmentData) => void
  onBack: () => void
  initialData?: Partial<EnvironmentData>
  selectedConfigurationTemplate?: any
  handleCancel: () => void
}

export type EnvironmentOption = {
  value: string
  label: string
}

export type SummaryFormProps = {
  onSubmit: () => void
  onBack: () => void
  projectData: ProjectData | null
  repositoryData: RepositoryData | null
  environmentData: EnvironmentData | null
}

const SHOPWARE_DATA = {
  project: {
    name: 'Project1',
    prospectName: 'kfc',
    prospectWebsite: 'https://www.kfc.com',
    techStack: 'shopware',
  },
  repository: {
    repoURI: 'git@github.com:Proofs-Sandbox/lulumelon-shopify-hydrogen.git',
    startCommitHash: '87fcf4ff0e7c9912332e02b96635e1a83c0d2a7d',
    githubKey: 'ghp_1234567890abcdef',
  },
  environment: {
    SHOPWARE_USER: 'lala',
    SHOPWARE_PASSWORD: 'bala',
  },
}
const EMPTY_DATA = {
  project: {},
  repository: {},
  environment: {},
}

const ALL_VALID = {
  project: true,
  repository: true,
  environment: true,
  summary: false,
}

const INITIAL_VALID = {
  project: false,
  repository: false,
  environment: false,
  summary: false,
}

function getValueFromIterationDefaultField(iterationDefault, namespace, key) {
  const iterationDefaultField = iterationDefault[namespace][key]
  if (typeof iterationDefaultField === 'object' && iterationDefaultField?.value) {
    return iterationDefaultField?.value
  } else {
    return iterationDefaultField
  }
}

function MDuplicateProjectWizard({
  onCreateProject,
  submittingInProgress,
  configurationTemplates,
  projectToDuplicate,
  iterationDefault,
  organizationId,
  teamId,
  handleCancel,
}) {
  const [selectedField, setSelectedField] = useState<string | null>(null)

  const initialValues = {
    project: projectToDuplicate?.projectConfiguration || {},
    repository: {
      repoURI: getValueFromIterationDefaultField(iterationDefault, 'repository', 'repoURI'),
      startCommitHash: getValueFromIterationDefaultField(
        iterationDefault,
        'repository',
        'startCommitHash'
      ),
      githubKey: getValueFromIterationDefaultField(iterationDefault, 'repository', 'githubKey'),
    },
    environment: Object.entries(iterationDefault?.environment).reduce((acc, entry) => {
      const [key, value] = entry
      return {
        ...acc,
        [key]: value.value,
      }
    }, {}),
  }
  if (!initialValues.project.techStack) {
    initialValues.project.techStack = iterationDefault?.usecase?.usecaseId
  }
  const [activeStep, setActiveStep] = useState<WizardStep>('project')
  const [completedSteps, setCompletedSteps] = useState<CompletedSteps>(ALL_VALID)
  const [formData, setFormData] = useState<WizardData>(initialValues)

  const selectedConfigurationTemplate = useMemo(() => {
    if (formData.project?.techStack) {
      return configurationTemplates.find(template => template.id === formData.project?.techStack)
    } else {
      const usecaseId = iterationDefault?.usecase?.usecaseId
      return configurationTemplates.find(template => template.id === usecaseId)
    }
    return null
  }, [configurationTemplates, formData.project?.techStack])

  const content = useHelpContent(activeStep, selectedField, selectedConfigurationTemplate)

  const handleStepChange = (step: WizardStep) => {
    if (!completedSteps[step] && step !== activeStep) {
      const steps: WizardStep[] = ['project', 'repository', 'environment', 'summary']
      const currentIndex = steps.indexOf(activeStep)
      const targetIndex = steps.indexOf(step)

      if (targetIndex > currentIndex && !completedSteps[steps[currentIndex]]) {
        return
      }
    }

    setActiveStep(step)
  }

  const handleProjectSubmit = async (data: ProjectData) => {
    try {
      if (formData.project?.techStack && data.techStack !== formData.project?.techStack) {
        setFormData(prev => ({ ...prev, repository: {}, environment: {} }))
      }
      // await new Promise(resolve => setTimeout(resolve, 500))
      const { organizationId, teamId, ...projectData } = data
      setFormData(prev => ({ ...prev, organizationId, teamId, project: projectData }))
      setCompletedSteps(prev => ({ ...prev, project: true }))
      setActiveStep('repository')
    } catch (error) {
      console.error('Error saving project data:', error)
    }
  }

  const handleRepositorySubmit = async (data: RepositoryData) => {
    try {
      // await new Promise(resolve => setTimeout(resolve, 500))
      setFormData(prev => ({ ...prev, repository: data }))
      setCompletedSteps(prev => ({ ...prev, repository: true }))
      setActiveStep('environment')
    } catch (error) {
      console.error('Error saving repository data:', error)
    }
  }

  const handleEnvironmentSubmit = async (data: EnvironmentData) => {
    try {
      // await new Promise(resolve => setTimeout(resolve, 500))
      setFormData(prev => ({ ...prev, environment: data }))
      setCompletedSteps(prev => ({ ...prev, environment: true }))
      setActiveStep('summary')
    } catch (error) {
      console.error('Error saving environment data:', error)
    }
  }

  const handleBuildPoc = async (prompt: string) => {
    onCreateProject(formData, selectedConfigurationTemplate, prompt)
  }

  const handleBack = () => {
    switch (activeStep) {
      case 'repository':
        setActiveStep('project')
        break
      case 'environment':
        setActiveStep('repository')
        break
      case 'summary':
        setActiveStep('environment')
        break
    }
  }

  if (submittingInProgress) {
    return (
      <div className="inline-flex h-[412px] w-full flex-col items-end justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
        <div className="inline-flex items-center justify-start gap-4 self-stretch">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-4">
            <div className="flex h-[380px] flex-col items-center justify-center gap-8 self-stretch rounded-lg border border-stone-200 bg-white px-8 py-20 shadow">
              <div className="inline-flex h-[120px] w-[120px] items-center justify-center rounded-full border border-[#954ee2] bg-gradient-to-b from-[#954ee2] to-[#954ee2]" />
              <div className="flex h-[68px] flex-col items-center justify-start gap-2 self-stretch">
                <div className="font-['PP Supply Sans'] text-center text-3xl font-normal leading-10 text-stone-900">
                  We’re building {formData.project.name} PoC
                </div>
                <div className="self-stretch text-center font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                  It shouldn’t take more than a couple of minutes...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Tabs
      value={activeStep}
      // onValueChange={handleStepChange}
      className=" w-full"
      orientation="vertical"
    >
      <div className="inline-flex w-full flex-col items-center justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
        <div className="inline-flex min-h-96 items-center justify-between gap-4 self-stretch">
          <div className="inline-flex  w-[204px] flex-col items-start justify-between self-stretch pb-14">
            <div className="flex flex-col items-start justify-start gap-4 self-stretch">
              <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
                New Project
              </div>

              <TabsList className="flex flex-col items-start justify-start gap-1 self-stretch rounded-md">
                <ProjectWizardTab
                  name="Project"
                  value="project"
                  activeStep={activeStep}
                  isValid={!!completedSteps.project}
                  Icon={FolderOpen}
                />
                <ProjectWizardTab
                  name="Repository"
                  value="repository"
                  activeStep={activeStep}
                  isValid={!!completedSteps.repository}
                  Icon={SiGithub}
                />
                <ProjectWizardTab
                  name="Environment"
                  value="environment"
                  activeStep={activeStep}
                  isValid={!!completedSteps.environment}
                  Icon={Container}
                />
                <ProjectWizardTab
                  name="Summary"
                  value="summary"
                  activeStep={activeStep}
                  isValid={!!completedSteps.summary}
                  Icon={FileText}
                />
              </TabsList>
            </div>
            <div className=" self-stretch font-['Inter'] text-sm font-normal leading-tight text-stone-500">
              {content?.text}
            </div>
          </div>
          <div className="flex-1">
            <TabsContent value="project" className="mt-0 w-full data-[state=inactive]:hidden">
              <ProjectForm
                onSubmit={handleProjectSubmit}
                initialData={formData.project}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                organizationId={organizationId}
                teamId={teamId}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="repository" className="mt-0 w-full data-[state=inactive]:hidden">
              <RepositoryForm
                onSubmit={handleRepositorySubmit}
                onBack={handleBack}
                initialData={formData.repository}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="environment" className="mt-0 w-full data-[state=inactive]:hidden">
              <EnvironmentForm
                onSubmit={handleEnvironmentSubmit}
                onBack={handleBack}
                initialData={formData.environment}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                selectedConfigurationTemplate={selectedConfigurationTemplate}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="summary" className="mt-0 w-full data-[state=inactive]:hidden">
              <SummaryForm
                onSubmit={handleBuildPoc}
                onBack={handleBack}
                formData={formData}
                submittingInProgress={submittingInProgress}
                handleCancel={handleCancel}
              />
            </TabsContent>
          </div>
        </div>
      </div>
    </Tabs>
  )
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  organizationId,
  teamId,
  handleCancel,
}) => {
  const [showRequirements, setShowRequirements] = React.useState(
    initialData?.requirements !== '' && initialData?.requirements != null
  )
  const getTeamsByOrgId = useStore(state => state.getTeamsByOrgId)
  const organizationsOptions = useStore(state =>
    state.organizations
      .filter(org => !org.is_reference)
      .map(org => ({ value: org.id, label: org.name }))
  )
  const initialSelectedOrganizationId =
    organizationId ?? (organizationsOptions.length === 1 ? organizationsOptions[0].value : '')
  const [teamOptions, setTeamOptions] = useState(() =>
    getTeamsByOrgId(initialSelectedOrganizationId)
      .filter(team => !team.is_reference)
      .map(org => ({ value: org.id, label: org.name }))
  )
  const initialSelectedTeamId = teamId ?? (teamOptions.length === 1 ? teamOptions[0].value : '')

  const showOrgAndTeamFields = organizationsOptions.length > 1 || teamOptions.length > 1

  const form = useForm<ProjectData>({
    resolver: zodResolver(projectSchema),
    defaultValues: {
      name: initialData?.name || '',
      prospectName: initialData?.prospectName || '',
      prospectWebsite: initialData?.prospectWebsite || '',
      techStack: initialData?.techStack || '',
      organizationId: initialSelectedOrganizationId,
      teamId: initialSelectedTeamId,
      requirements: initialData?.requirements || '',
    },
    mode: 'onChange',
  })

  const organizationIdField = form.watch('organizationId')

  useEffect(() => {
    setTeamOptions(
      getTeamsByOrgId(organizationIdField)
        .filter(team => !team.is_reference)
        .map(org => ({ value: org.id, label: org.name }))
    )
  }, [organizationIdField, getTeamsByOrgId])

  React.useEffect(() => {
    form.setFocus('name')
  }, [form.setFocus])

  const toggleRequirements = () => {
    setShowRequirements(!showRequirements)
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex  w-full  flex-col items-start justify-start gap-5 self-stretch overflow-y-auto rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Project details
        </div>
        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Project</label>
          <Input
            {...form.register('name')}
            placeholder="Project name"
            className="my-custom-focus"
            onFocus={() => onSelectField('name')}
          />
          {form.formState.errors.name && (
            <p className="text-sm text-red-500">{form.formState.errors.name.message}</p>
          )}
        </div>

        {showOrgAndTeamFields && (
          <Controller
            name="organizationId"
            control={form.control}
            onFocus={() => onSelectField('organizationId')}
            render={({ field, fieldState: { error } }) => (
              <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
                <Label htmlFor="technologies" className="text-sm font-medium text-gray-700">
                  Organization
                </Label>
                <Select
                  onFocus={() => onSelectField('organizationId')}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
                >
                  <SelectTrigger
                    onClick={e => e.stopPropagation()}
                    onFocus={() => onSelectField('organizationId')}
                    // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                    tabIndex={0}
                    className="my-custom-focus mt-1"
                  >
                    <SelectValue placeholder="Select an organization..." />
                  </SelectTrigger>
                  <SelectContent className="w-full" onFocus={() => onSelectField('organizationId')}>
                    {organizationsOptions.map(option => {
                      return (
                        <SelectItem key={option.value} value={option.value}>
                          <div className="flex items-center justify-start gap-3">
                            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                              {option.label}
                            </div>
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>

                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
        )}

        {showOrgAndTeamFields && (
          <Controller
            name="teamId"
            control={form.control}
            onFocus={() => onSelectField('teamId')}
            render={({ field, fieldState: { error } }) => (
              <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
                <Label htmlFor="technologies" className="text-sm font-medium text-gray-700">
                  Team
                </Label>
                <Select
                  onFocus={() => onSelectField('teamId')}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
                >
                  <SelectTrigger
                    onClick={e => e.stopPropagation()}
                    onFocus={() => onSelectField('teamId')}
                    // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                    tabIndex={0}
                    className="my-custom-focus mt-1"
                  >
                    <SelectValue placeholder="Select a team..." />
                  </SelectTrigger>
                  <SelectContent className="w-full" onFocus={() => onSelectField('teamId')}>
                    {teamOptions.map(option => {
                      return (
                        <SelectItem key={option.value} value={option.value}>
                          <div className="flex items-center justify-start gap-3">
                            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                              {option.label}
                            </div>
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>

                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
        )}

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Prospect</label>
          <Input
            {...form.register('prospectName')}
            placeholder="Prospect"
            className="my-custom-focus"
            onFocus={() => onSelectField('prospectName')}
          />
          {form.formState.errors.prospectName && (
            <p className="text-sm text-red-500">{form.formState.errors.prospectName.message}</p>
          )}
        </div>
        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Prospect website</label>
          <Input
            {...form.register('prospectWebsite')}
            placeholder="https://www.example.com"
            type="text"
            className="my-custom-focus"
            onFocus={() => onSelectField('prospectWebsite')}
          />
          {form.formState.errors.prospectWebsite && (
            <p className="text-sm text-red-500">{form.formState.errors.prospectWebsite.message}</p>
          )}
        </div>

        <Controller
          name="techStack"
          control={form.control}
          onFocus={() => onSelectField('techStack')}
          render={({ field, fieldState: { error } }) => (
            <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
              <Label htmlFor="technologies" className="text-sm font-medium text-gray-700">
                Tech stack
              </Label>
              <Select
                onFocus={() => onSelectField('techStack')}
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
              >
                <SelectTrigger
                  onFocus={() => onSelectField('techStack')}
                  // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                  tabIndex={0}
                  className="my-custom-focus mt-1"
                >
                  <SelectValue placeholder="Select a stack..." />
                </SelectTrigger>
                <SelectContent className="w-full" onFocus={() => onSelectField('technologies')}>
                  {technologiesOptions.map(option => {
                    const stack = option.value
                    const TechnologyIcon = stackTemplate[stack]?.icon
                    return (
                      <SelectItem
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                      >
                        <div className="flex items-center justify-start gap-3">
                          <div className="flex size-4 items-center justify-center">
                            {TechnologyIcon && <TechnologyIcon />}
                          </div>
                          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                            {option.label}
                          </div>
                        </div>
                      </SelectItem>
                    )
                  })}
                </SelectContent>
              </Select>

              {error && <p className="text-red-500">{error.message}</p>}
            </div>
          )}
        />

        {!showRequirements ? (
          <Button
            type="button"
            variant="outline"
            onClick={toggleRequirements}
            className="self-start"
          >
            + Requirements
          </Button>
        ) : (
          <div className="space-y-2 self-stretch">
            <label className="text-sm font-medium">Additional Requirements</label>
            <Textarea
              {...form.register('requirements')}
              placeholder="Enter additional requirements..."
              className="my-custom-focus min-h-[50px]"
              onFocus={() => onSelectField('requirements')}
              rows={3}
            />
            {form.formState.errors.requirements && (
              <p className="text-sm text-red-500">{form.formState.errors.requirements.message}</p>
            )}
          </div>
        )}
      </div>

      <div className="inline-flex items-start justify-between self-stretch">
        <div
          className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
          onClick={handleCancel}
        >
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
            Cancel
          </div>
        </div>
        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="w-full bg-black  text-white"
          >
            Next
            {/*<ArrowRight className="ml-2 size-4" />*/}
          </Button>
        </div>
      </div>
    </form>
  )
}

const RepositoryForm: React.FC<RepositoryFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  onBack,
  handleCancel,
}) => {
  const form = useForm<RepositoryData>({
    resolver: zodResolver(repositorySchema),
    defaultValues: {
      repoURI: initialData?.repoURI || '',
      startCommitHash: initialData?.startCommitHash || '',
      githubKey: initialData?.githubKey || '',
    },
    mode: 'onChange',
  })

  React.useEffect(() => {
    form.setFocus('repoURI')
  }, [form.setFocus])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex w-full flex-col items-start justify-start gap-6 self-stretch rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Repository setup
        </div>

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <Label className="text-sm font-medium" aria-required="true">
            Repository URL <span className="text-destructive">*</span>
          </Label>
          <Input
            {...form.register('repoURI')}
            placeholder="git@github.com:org/repo.git"
            className="my-custom-focus"
            onFocus={() => onSelectField?.('repoURI')}
          />
          {form.formState.errors.repoURI && (
            <p className="text-sm text-red-500">{form.formState.errors.repoURI.message}</p>
          )}
        </div>

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <Label className="text-sm font-medium">Start commit hash</Label>
          <Input
            {...form.register('startCommitHash')}
            placeholder="1234567890abcdef"
            className="my-custom-focus"
            onFocus={() => onSelectField?.('startCommitHash')}
          />
          {form.formState.errors.startCommitHash && (
            <p className="text-sm text-red-500">{form.formState.errors.startCommitHash.message}</p>
          )}
        </div>

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <Label className="text-sm font-medium" aria-required="true">
            GitHub PAT (Personal Access Token) <span className="text-destructive">*</span>
          </Label>
          <Input
            {...form.register('githubKey')}
            type="password"
            placeholder="Enter your GitHub key"
            className="my-custom-focus"
            onFocus={() => onSelectField?.('githubKey')}
          />
          {form.formState.errors.githubKey && (
            <p className="text-sm text-red-500">{form.formState.errors.githubKey.message}</p>
          )}
        </div>
      </div>

      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex">
          <div
            className="flex size-10 cursor-pointer items-center justify-center gap-2 rounded-md px-4 py-2"
            onClick={onBack}
          >
            <div className="relative flex size-4 items-center">
              <MoveLeft />
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
            onClick={handleCancel}
          >
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              Cancel
            </div>
          </div>
        </div>

        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="w-full bg-black text-white"
          >
            Next
          </Button>
        </div>
      </div>
    </form>
  )
}

const EnvironmentForm: React.FC<EnvironmentFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  onBack,
  selectedConfigurationTemplate,
  handleCancel,
}) => {
  const adminFields = useAnalystEnvFields()
  const fields = useMemo(
    () => [
      ...adminFields,
      ...(selectedConfigurationTemplate?.iterationDefaultsTemplate?.environment?.fields ?? []),
    ],
    [adminFields, selectedConfigurationTemplate?.iterationDefaultsTemplate?.environment?.fields]
  )
  const defaultValues = useMemo((): Record<string, string | boolean> => {
    return fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]:
          initialData && initialData[field.key]
            ? initialData[field.key]
            : (field.default_value ?? ''),
      }),
      {}
    )
  }, [fields, initialData])

  const form = useForm({
    defaultValues: defaultValues,
    mode: 'all',
  })
  React.useEffect(() => {
    if (fields[0]) {
      form.setFocus(fields[0].key)
    }
  }, [form.setFocus, fields, form])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex h-[576px] w-full flex-col items-start justify-start gap-8 self-stretch overflow-y-scroll rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Environment setup
        </div>

        {(!fields || fields?.length === 0) && (
          <div>No environment setup required for this stack.</div>
        )}

        {fields.map(field => {
          return (
            <div
              className={cx('flex flex-col items-start justify-start gap-2 self-stretch')}
              key={field.key}
            >
              <Label
                htmlFor={field.key}
                className="self-stretch font-['Inter'] text-sm font-medium leading-[14px] text-stone-900"
              >
                {field.key}
                {field.required && <span className="text-destructive"> *</span>}
              </Label>
              {field.type === 'text-long' && (
                <Textarea
                  {...form.register(field.key, {
                    required: field.required ? 'Field is required' : false,
                  })}
                  placeholder={field.placeholder}
                  className={cx(
                    'my-custom-focus',
                    field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                  )}
                  onFocus={() => onSelectField?.(field.key)}
                  invalid={!!form.formState.errors[field.key]}
                />
              )}
              {field.type === 'boolean' ? (
                <Controller
                  name={field.key}
                  control={form.control}
                  render={({ field: formField }) => (
                    <Checkbox
                      checked={formField.value === 'true'}
                      onChange={(value: boolean) => formField.onChange(value.toString())}
                      name={field.key}
                      onFocus={() => onSelectField?.(field.key)}
                      invalid={!!form.formState.errors[field.key]}
                    />
                  )}
                ></Controller>
              ) : (
                <Input
                  {...form.register(field.key, {
                    required: field.required ? 'Field is required' : false,
                  })}
                  placeholder={field.placeholder}
                  className={cx(
                    'my-custom-focus',
                    field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                  )}
                  onFocus={() => onSelectField?.(field.key)}
                  invalid={!!form.formState.errors[field.key]}
                />
              )}

              {form.formState.errors[field.key] && (
                <p className="text-sm text-red-500">{form.formState.errors[field.key]?.message}</p>
              )}
            </div>
          )
        })}
      </div>

      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex">
          <div
            className="flex size-10 cursor-pointer items-center justify-center gap-2 rounded-md px-4 py-2"
            onClick={onBack}
          >
            <div className="relative flex size-4 items-center">
              <MoveLeft />
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
            onClick={handleCancel}
          >
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              Cancel
            </div>
          </div>
        </div>
        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="w-full bg-black text-white"
          >
            Next
          </Button>
        </div>
      </div>
    </form>
  )
}

function SummaryForm({ onSubmit, onBack, formData, submittingInProgress, handleCancel }) {
  const isSuperAdmin = useIsUserSuperAdmin()

  const [editablePrompt, setEditablePrompt] = useState(() => constructPrompt(formData?.project))

  return (
    <div className="flex flex-col gap-4">
      <div className="inline-flex h-[576px] flex-col items-start justify-start gap-8 overflow-y-scroll rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Proof of Concept for {formData.project.prospectName}.
        </div>
        <div className="flex flex-col items-start justify-start self-stretch">
          <div className="inline-flex items-start justify-start gap-2 self-stretch border-b border-t border-stone-200 py-3">
            <div className="w-32 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Prospect:
            </div>
            <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-900">
              {formData.project.prospectName}
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-2 self-stretch border-b border-t border-stone-200 py-3">
            <div className="w-32 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Website:
            </div>
            <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-900">
              {formData.project.prospectWebsite}
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-2 self-stretch border-b border-t border-stone-200 py-3">
            <div className="w-32 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Date:
            </div>
            <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-900">
              {new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-4 self-stretch font-['Inter'] text-base font-normal text-stone-900">
          <div className="font-medium">Objective</div>
          {isSuperAdmin ? (
            <Textarea
              value={editablePrompt}
              onChange={e => setEditablePrompt(e.target.value)}
              className="resize-none border-none focus:outline-none"
              rows={5}
            />
          ) : (
            <div className="whitespace-pre-wrap">{editablePrompt}</div>
          )}
        </div>
        <div className="flex h-16 flex-col items-start justify-start gap-4 self-stretch">
          <div className="self-stretch font-['Inter'] text-base font-medium leading-normal text-stone-900">
            Technology
          </div>
          <div className="flex h-6 flex-col items-start justify-start gap-1 self-stretch">
            <div className="inline-flex items-start justify-start gap-1.5 self-stretch">
              <div className="flex items-center justify-start gap-1 rounded-md bg-stone-100 px-1.5">
                <div className="flex size-4 items-center justify-center px-px">
                  <div className="relative flex h-4 w-3.5 flex-col items-start justify-start" />
                </div>
                <div className="font-['Inter'] text-base font-normal leading-normal text-stone-900">
                  {stackTemplate[formData.project.techStack]?.label}
                </div>
              </div>
              <div className="font-['Inter'] text-base font-normal leading-normal text-stone-900">
                as main e-commerce system.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex">
          <div
            className="flex size-10 cursor-pointer items-center justify-center gap-2 rounded-md px-4 py-2"
            onClick={onBack}
          >
            <div className="relative flex size-4 items-center">
              <MoveLeft />
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
            onClick={handleCancel}
          >
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              Cancel
            </div>
          </div>
        </div>
        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            onClick={() => onSubmit(editablePrompt)}
            className="w-full bg-black text-white"
            disabled={submittingInProgress}
          >
            {submittingInProgress ? 'Building PoC...' : 'Build PoC'}
          </Button>
        </div>
      </div>
    </div>
  )
}

function useDuplicateProject() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const navigate = useNavigate()

  /**
   * Create a new project.
   */
  const createFn = async ({
    organizationId,
    teamId,
    formData,
    usecaseFields,
    templateId,
    iterationDefault,
    prompt,
  }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)

    try {
      // pick integer from 1 to 10 randomly
      const randomImage = Math.floor(Math.random() * 10) + 1
      // Payload should look something like this:
      const payload = {
        organizationId,
        teamId,
        projectData: {
          name: formData.name,
          description: formData.description,
          projectConfiguration: formData.projectConfiguration,
          image: `${randomImage}`,
          iterationDefaults: {
            environment: formData.environment.reduce((acc, { key, value, dontEncrypt }) => {
              if (value != null && value !== '') {
                const existingValue = iterationDefault.environment[key]
                if (value === existingValue?.value) {
                  acc[key] = { ...existingValue, copyFieldAsItIs: true }
                } else {
                  acc[key] = { value, dontEncrypt }
                }
              }
              return acc
            }, {}),
            repository: formData.repository.reduce((acc, { key, value }) => {
              if (key === 'githubKey' && value && value !== '') {
                const existingValue = iterationDefault.repository[key]
                if (value === existingValue?.value) {
                  acc[key] = { ...existingValue, copyFieldAsItIs: true }
                } else {
                  acc[key] = { value, dontEncrypt: false }
                }
              } else {
                acc[key] = value
              }

              return acc
            }, {}),
            usecase: usecaseFields.reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {}),
            templateId: templateId || null,
          },
        },
      }
      const response = await createProjectFirebaseFunction(payload)
      analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_CREATED, {
        organizationId,
        teamId,
        templateId,
        projectId: response?.projectId || 'N/A',
        projectName: formData.name,
      })
      const projectId = response.data.projectId
      const iterationResponse = await createIterationForProject({
        projectId,
        prompt,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration: formData.projectConfiguration,
      })
      setResponse(iterationResponse)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      navigate(
        `/projects/${iterationResponse.data.projectId}?iteration=${iterationResponse.data.iterationId}`
      )
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createFn, resetCreateFn }
}

function useCreateIteration() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new iteration for a project.
   * @param {Object} options - The options for the function.
   * @param {String} options.projectId - The ID of the project.
   * @param {String} options.prompt - The prompt for the iteration.

   * @returns {Promise<Object>} A promise that resolves when the iteration is created.
   */
  const createIteration = async ({
    projectId,
    prompt,
    sourceIterationId,
    configuration,
    usecase,
  }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const response = await createIterationForProject({
        projectId,
        prompt,
        sourceIterationId,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration,
        usecase,
      })
      setResponse(response)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateIteration() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createIterationFn: createIteration, resetCreateIteration }
}
