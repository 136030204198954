import React, { useState, useEffect } from 'react'
import { Dialog } from '@/components/catalyst/dialog'
import { useForm, Controller } from 'react-hook-form'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Checkbox } from '@/components/ui/checkbox.js'
import useStore from '@/stores/useStore'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { createAndInviteUserFirebaseFunction } from '@/services/Firebase'
import { useToast } from '@/components/ui/use-toast'
import { useMutation } from '@tanstack/react-query'

// Define the form schema
const userSchema = z.object({
  id: z.string().optional(),
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email address'),
  organizationId: z.string().min(1, 'Organization is required'),
  isExternal: z.boolean().default(false),
  isOnboarding: z.boolean().default(false),
  projectToOnboard: z.string().optional(),
})

type UserFormData = z.infer<typeof userSchema>

interface Organization {
  id: string
  name: string
  is_reference: boolean
}

interface Project {
  id: string
  name: string
  is_reference: boolean
}

interface Team {
  id: string
  name: string
  organizationId: string
  is_reference: boolean
}

interface AddUserDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: UserFormData) => void
}

export default function AddUserDialog({ isOpen, onClose, onSubmit }: AddUserDialogProps) {
  const organizations = useStore(state => state.organizations) as Organization[]
  const projects = useStore(state =>
    state.projects.filter((project: Project) => project.is_reference)
  ) as Project[]
  const { toast } = useToast()

  const form = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      isExternal: false,
      isOnboarding: false,
    },
  })

  const { mutate: handleSubmit, isPending: isSubmitting } = useMutation({
    mutationFn: (data: UserFormData) => {
      return createAndInviteUserFirebaseFunction({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        organizationId: data.organizationId,
        isExternal: data.isExternal,
        isOnboarding: data.isOnboarding,
        ...(data.isOnboarding && { projectToOnboard: data.projectToOnboard }),
      })
    },
    onError: error => {
      console.error('Error creating user:', error)
      toast({
        variant: 'destructive',
        title: 'Error creating user 😔',
        description: error instanceof Error ? error.message : 'Please try again.',
      })
    },
    onSuccess: (_data, variables) => {
      toast({
        title: 'User created successfully! 🎉',
        description: `${variables.firstName} ${variables.lastName} has been added.`,
      })
      onSubmit()
    },
  })

  return (
    <Dialog
      className="max-h-[90vh] overflow-hidden p-0"
      size="2xl"
      open={isOpen}
      onClose={onClose}
      static={true}
      preventOutsideClick={true}
    >
      <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
        <div className="flex w-full flex-col items-start justify-start gap-6 self-stretch rounded-lg border border-stone-200 bg-white p-8 shadow">
          <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
            Add New User
          </div>

          <div className="grid w-full gap-6">
            {/* Name fields */}
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <Label>First Name</Label>
                <Input
                  {...form.register('firstName')}
                  placeholder="John"
                  className="my-custom-focus"
                />
                {form.formState.errors.firstName && (
                  <p className="text-sm text-red-500">{form.formState.errors.firstName.message}</p>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <Label>Last Name</Label>
                <Input
                  {...form.register('lastName')}
                  placeholder="Doe"
                  className="my-custom-focus"
                />
                {form.formState.errors.lastName && (
                  <p className="text-sm text-red-500">{form.formState.errors.lastName.message}</p>
                )}
              </div>
            </div>

            {/* Email field */}
            <div className="flex flex-col gap-2">
              <Label>Email</Label>
              <Input
                {...form.register('email')}
                type="email"
                placeholder="john.doe@example.com"
                className="my-custom-focus"
              />
              {form.formState.errors.email && (
                <p className="text-sm text-red-500">{form.formState.errors.email.message}</p>
              )}
            </div>

            {/* Organization selector */}
            <Controller
              name="organizationId"
              control={form.control}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <Label>Organization</Label>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger className="my-custom-focus">
                      <SelectValue placeholder="Select an organization" />
                    </SelectTrigger>
                    <SelectContent>
                      {organizations
                        .filter(org => !org.is_reference)
                        .map(org => (
                          <SelectItem key={org.id} value={org.id}>
                            {org.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  {form.formState.errors.organizationId && (
                    <p className="text-sm text-red-500">
                      {form.formState.errors.organizationId.message}
                    </p>
                  )}
                </div>
              )}
            />

            {/* Checkbox fields */}
            <div className="flex gap-6">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="isExternal"
                  {...form.register('isExternal')}
                  onCheckedChange={(checked: boolean) => form.setValue('isExternal', checked)}
                />
                <Label htmlFor="isExternal">External User</Label>
              </div>

              <div className="flex items-center space-x-2">
                <Checkbox
                  id="isOnboarding"
                  {...form.register('isOnboarding')}
                  onCheckedChange={(checked: boolean) => {
                    form.setValue('isOnboarding', checked)
                    if (!checked) {
                      form.setValue('projectToOnboard', undefined)
                    }
                  }}
                />
                <Label htmlFor="isOnboarding">Onboarding</Label>
              </div>
            </div>

            {/* Project selector - only shown when isOnboarding is true */}
            {form.watch('isOnboarding') && (
              <Controller
                name="projectToOnboard"
                control={form.control}
                render={({ field }) => (
                  <div className="flex flex-col gap-2">
                    <Label>Project to Onboard</Label>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger className="my-custom-focus">
                        <SelectValue placeholder="Select a reference project" />
                      </SelectTrigger>
                      <SelectContent>
                        {projects.length === 0 ? (
                          <div className="text-center py-2 text-sm text-muted-foreground">
                            No reference projects available
                          </div>
                        ) : (
                          projects.map(project => (
                            <SelectItem key={project.id} value={project.id}>
                              {project.name}
                            </SelectItem>
                          ))
                        )}
                      </SelectContent>
                    </Select>
                    {form.formState.errors.projectToOnboard && (
                      <p className="text-sm text-red-500">
                        {form.formState.errors.projectToOnboard.message}
                      </p>
                    )}
                  </div>
                )}
              />
            )}
          </div>
        </div>

        {/* Form actions */}
        <div className="inline-flex items-start justify-between self-stretch p-4">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            disabled={isSubmitting}
            className="flex items-center justify-center gap-2"
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Creating User...' : 'Add User'}
          </Button>
        </div>
      </form>
    </Dialog>
  )
}
