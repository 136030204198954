import { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { EnvironmentField } from './types.ts'

export function useAnalystEnvFields(): EnvironmentField[] {
  const isSuperAdmin = useIsUserSuperAdmin()

  if (!isSuperAdmin) {
    return []
  }

  return [
    {
      required: false,
      description: 'Should the business analyst be used?',
      name: 'PROOFS_USE_BUSINESS_ANALYST',
      group: 'Admin',
      key: 'PROOFS_USE_BUSINESS_ANALYST',
      dontEncrypt: true,
      type: 'boolean',
      default_value: 'false',
      is_internal: true,
    },

    {
      required: false,
      description: 'Should the business analyst be used?',
      name: 'PROOFS_USE_TECHNICAL_ANALYST',
      group: 'Admin',
      key: 'PROOFS_USE_TECHNICAL_ANALYST',
      dontEncrypt: true,
      type: 'boolean',
      default_value: 'false',
      is_internal: true,
    },
  ]
}
