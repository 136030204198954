import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'

import { XCircleIcon } from '@heroicons/react/16/solid'
import { BadgeButton } from '@/components/catalyst/badge'

import { Button } from '@/components/catalyst/button'

import { Field, Description } from '@/components/catalyst/fieldset'
import { Label } from '@/components/catalyst/fieldset'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox'
import { Input } from '@/components/catalyst/input'

/**
 * Editor for the languages field
 * @param {object} props
 * @param {object} props.formControl - The react-hook-form form control object
 * @param {string[]} props.availableLanguages - The available languages
 * @param {function} props.onAddLanguage - The function to call when a language is added
 * @param {function} props.onRemoveLanguage - The function to call when a language is removed
 * @returns {JSX.Element}
 */
export function MFormLanguagesEditor({
  formControl,
  availableLanguages,
  onAddLanguage,
  onRemoveLanguage,
}) {
  return (
    <Field className="rounded-sm bg-zinc-100 p-4">
      <Label>
        <span className="text-base font-semibold">Languages</span>
      </Label>
      <Description>Programming languages the code snippet references</Description>
      <div className="flex min-h-14 flex-wrap pt-4">
        <Controller
          control={formControl}
          name="languages"
          render={({ field: { value } }) =>
            value?.map(lang => (
              <BadgeButton
                key={lang}
                color="zinc"
                className="mb-4 mr-2 capitalize"
                onClick={() => {
                  onRemoveLanguage(lang)
                }}
              >
                {lang} <XCircleIcon className="ml-1 size-4" />
              </BadgeButton>
            ))
          }
        />
      </div>
      <div className="w-48">
        <Label>Add language</Label>
        <Listbox
          name="new-language"
          onChange={e => onAddLanguage(e)}
          defaultValue="none"
          value="none"
        >
          {availableLanguages?.map(lang => (
            <ListboxOption key={lang} value={lang}>
              <ListboxLabel className="capitalize">{lang}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      </div>
    </Field>
  )
}
MFormLanguagesEditor.propTypes = {
  formControl: PropTypes.object.isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddLanguage: PropTypes.func.isRequired,
  onRemoveLanguage: PropTypes.func.isRequired,
}

/**
 * Editor for the services field
 * @param {object} props
 * @param {object} props.formControl - The react-hook-form form control object
 * @param {function} props.onAddService - The function to call when a service is added
 * @param {function} props.onRemoveService - The function to call when a service is removed
 * @returns {JSX.Element}
 */
export function MFormServicesEditor({ formControl, onAddService, onRemoveService }) {
  const [newService, setNewService] = useState('')
  const handleInputChange = useCallback(e => {
    setNewService(e.target.value)
  }, [])

  const handleAddService = useCallback(() => {
    if (newService) {
      onAddService(newService)
      setNewService('')
    }
  }, [newService, onAddService])

  const handleEnterKeyPressed = event => {
    if (event.keyCode === 13) {
      // Check if Enter key was pressed
      handleAddService()
    }
  }
  return (
    <Field className="rounded-sm bg-zinc-100 p-4">
      <Label>
        <span className="text-base font-semibold">Services</span>
      </Label>
      <Description>Service can be a framework (React) or something like Contentful</Description>
      <div className="flex min-h-14 flex-wrap pt-4">
        <Controller
          control={formControl}
          name="services"
          render={({ field: { value } }) =>
            value?.map(service => (
              <BadgeButton
                key={service}
                color="zinc"
                className="mb-4 mr-2"
                onClick={() => {
                  onRemoveService(service)
                }}
              >
                {service} <XCircleIcon className="ml-1 size-4" />
              </BadgeButton>
            ))
          }
        />
      </div>
      <div className="w-96">
        <Label>Add service</Label>
        <div className="flex gap-x-4">
          <Input
            name="new-service"
            value={newService}
            onChange={handleInputChange}
            onKeyDown={handleEnterKeyPressed}
          />
          <Button onClick={handleAddService}>Add</Button>
        </div>
      </div>
    </Field>
  )
}
MFormServicesEditor.propTypes = {
  formControl: PropTypes.object.isRequired,
  onAddService: PropTypes.func.isRequired,
  onRemoveService: PropTypes.func.isRequired,
}

/**
 * Editor for the libraries field
 * @param {object} props
 * @param {object} props.formControl - The react-hook-form form control object
 * @param {function} props.onAddLibrary - The function to call when a library is added
 * @param {function} props.onRemoveLibrary - The function to call when a library is removed
 * @returns {JSX.Element}
 */
export function MFormLibrariesEditor({ formControl, onAddLibrary, onRemoveLibrary }) {
  const [newLibrary, setNewLibrary] = useState('')
  const handleInputChange = useCallback(e => {
    setNewLibrary(e.target.value)
  }, [])

  const handleaddLibrary = useCallback(() => {
    if (newLibrary) {
      onAddLibrary(newLibrary)
      setNewLibrary('')
    }
  }, [newLibrary, onAddLibrary])

  const handleEnterKeyPressed = event => {
    if (event.keyCode === 13) {
      // Check if Enter key was pressed
      handleaddLibrary() // Call your function
    }
  }
  return (
    <Field className="rounded-sm bg-zinc-100 p-4">
      <Label>
        <span className="text-base font-semibold">Libraries</span>
      </Label>
      <Description>
        Example: contentful/react, python/pydantic, javascript/react/zustand
      </Description>
      <div className="flex min-h-14 flex-wrap pt-4">
        <Controller
          control={formControl}
          name="libraries"
          render={({ field: { value } }) =>
            value?.map(library => (
              <BadgeButton
                key={library}
                color="zinc"
                className="mb-4 mr-2"
                onClick={() => {
                  onRemoveLibrary(library)
                }}
              >
                {library} <XCircleIcon className="ml-1 size-4" />
              </BadgeButton>
            ))
          }
        />
      </div>
      <div className="w-96">
        <Label>Add library</Label>
        <div className="flex gap-x-4">
          <Input
            name="new-library"
            value={newLibrary}
            onChange={handleInputChange}
            onKeyDown={handleEnterKeyPressed}
          />
          <Button onClick={handleaddLibrary}>Add</Button>
        </div>
      </div>
    </Field>
  )
}
MFormLibrariesEditor.propTypes = {
  formControl: PropTypes.object.isRequired,
  onAddLibrary: PropTypes.func.isRequired,
  onRemoveLibrary: PropTypes.func.isRequired,
}

/**
 * Editor for the role_types field
 * @param {object} props
 * @param {object} props.formControl - The react-hook-form form control object
 * @param {string[]} props.availableRoleTypes - The available role types
 * @param {function} props.onAddRoleType - The function to call when a role type is added
 * @param {function} props.onRemoveRoleType - The function to call when a role type is removed
 * @returns {JSX.Element}
 */
export function MFormRoleTypesEditor({
  formControl,
  availableRoleTypes,
  onAddRoleType,
  onRemoveRoleType,
}) {
  return (
    <Field className="rounded-sm bg-zinc-100 p-4">
      <Label>
        <span className="text-base font-semibold">RoleTypes</span>
      </Label>
      <Description>Which agent should use this hint?</Description>
      <div className="flex min-h-14 flex-wrap pt-4">
        <Controller
          control={formControl}
          name="role_types"
          render={({ field: { value } }) =>
            value?.map(roleType => (
              <BadgeButton
                key={roleType}
                color="zinc"
                className="mb-4 mr-2"
                onClick={() => {
                  onRemoveRoleType(roleType)
                }}
              >
                {roleType} <XCircleIcon className="ml-1 size-4" />
              </BadgeButton>
            ))
          }
        />
      </div>
      <div className="w-48">
        <Label>Add Role</Label>
        <Listbox
          name="new-language"
          onChange={e => onAddRoleType(e)}
          defaultValue="none"
          value="none"
        >
          {availableRoleTypes?.map(roleType => (
            <ListboxOption key={roleType} value={roleType}>
              <ListboxLabel>{roleType}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      </div>
    </Field>
  )
}
MFormRoleTypesEditor.propTypes = {
  formControl: PropTypes.object.isRequired,
  availableRoleTypes: PropTypes.array.isRequired,
  onAddRoleType: PropTypes.func.isRequired,
  onRemoveRoleType: PropTypes.func.isRequired,
}

/**
 * Editor for the content_type field
 * @param {object} props
 * @param {object} props.formControl - The react-hook-form form control object
 * @param {string[]} props.availableContentTypes - The available content types
 * @returns {JSX.Element}
 */
export function MFormContentTypeEditor({ formControl, availableContentTypes }) {
  return (
    <Field className="flex flex-col justify-between rounded-sm bg-zinc-100 p-4">
      <div>
        <Label>
          <span className="text-base font-semibold">Content type</span>
        </Label>
        <Description>
          Code type for editor interpretation (not the programming language of the snippet)
        </Description>
      </div>
      <Controller
        control={formControl}
        name="content_type"
        render={({ field: { onChange, value } }) => (
          <Listbox onChange={onChange} value={value} className="max-w-48">
            {availableContentTypes?.map(contentType => (
              <ListboxOption key={contentType} value={contentType}>
                <ListboxLabel className="capitalize">{contentType}</ListboxLabel>
              </ListboxOption>
            ))}
          </Listbox>
        )}
      />
    </Field>
  )
}
MFormContentTypeEditor.propTypes = {
  formControl: PropTypes.object.isRequired,
  availableContentTypes: PropTypes.array.isRequired,
}
