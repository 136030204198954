import { useCallback, useMemo, useState } from 'react'
import { useToast } from '@/components/ui/use-toast'
import PropTypes from 'prop-types'
import { Input } from '@/components/catalyst/input.jsx'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const.js'
import { Textarea } from '@/components/catalyst/textarea.jsx'

import { Button } from '@/components/catalyst/button.jsx'
import { sendUserCommand } from '@/lib/sendUserCommand'

export default function MContinuationPrompt2({ iterationId }) {
  const [prompt, setPrompt] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const [shouldHide, setShouldHide] = useState(false)
  const [blueprintId, setBlueprintId] = useState(null)

  const { toast } = useToast()

  const sendIterationCommand = useCallback(async () => {
    if (!prompt || prompt?.length === 0 || !iterationId) {
      toast({
        variant: 'destructive',
        title: 'We need instructions!',
        description: 'Please provide instructions on how to continue',
      })
      return
    }
    setIsWorking(true)
    try {
      // await sendUserCommand(iterationId, {
      //   command: ITERATION_COMMANDS.EXTEND,
      //   commandArgs: {
      //     prompt,
      //     iteration_id: iterationId,
      //     blueprint_id: blueprintId,
      //   },
      // })
      setPrompt(false)
      setShouldHide(true)
    } catch (error) {
      console.error('Error creating continuation prompt', error)
      toast({
        variant: 'destructive',
        title: 'Error extending iteration 😔',
        description: 'Try refreshing the page or contact Proofs team.',
      })
    } finally {
      setIsWorking(false)
    }
  }, [prompt, iterationId, blueprintId, toast])

  const shouldAllowSubmitting = useMemo(() => {
    return !isWorking && prompt && prompt?.length > 0
  }, [prompt, isWorking])

  if (shouldHide) {
    return null
  }

  return (
    <div className="mt-auto">
      <div className=" mb-4 mt-4 w-full border border-dashed border-zinc-200" />
      <div className="mx-12 flex flex-col items-center justify-center rounded-md border border-zinc-200 bg-zinc-50 p-4 shadow-inner">
        <div className="mb-4 w-full">
          <Input
            placeholder="Blueprint ID (optional)"
            value={blueprintId || ''}
            onChange={e => setBlueprintId(e.target.value)}
          />
        </div>
        <Textarea
          className="w-full"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
          placeholder="Your instructions on what to do next..."
        />
        <div className="mt-4 flex w-full justify-end">
          <Button
            disabled={!shouldAllowSubmitting}
            className="ml-4 cursor-pointer"
            color="zinc"
            onClick={sendIterationCommand}
          >
            {isWorking ? 'Working...' : 'Continue iteration'}
          </Button>
        </div>
        <div className="mt-4 flex w-full items-center justify-end">
          <p className="text-xs text-zinc-500">
            Proofs will continue iteration with provided instructions
          </p>
        </div>
      </div>
      {/* <div className="mb-4 mt-4 w-full border border-dashed border-zinc-200" /> */}
    </div>
  )
}

MContinuationPrompt2.propTypes = {
  iterationId: PropTypes.string,
}
