import { z } from 'zod'

export const repositorySchema = z
  .object({
    repoURI: z
      .string()
      .optional()
      .refine(
        url => !url || isValidGitHubUrl(url),
        'Must be a valid GitHub repository URL (HTTPS or SSH format)'
      ),
    startCommitHash: z
      .string()
      .optional()
      .refine(
        val => !val || /^[a-f0-9]{40}$|^[a-f0-9]{7}$/.test(val),
        'Invalid commit hash format'
      ),
    githubKey: z.string().optional(),
    githubUsername: z.string().optional(),
  })
  .refine(
    data => {
      if (data.githubUsername) {
        // If username is provided, only repoURI is required
        return !!data.repoURI
      }

      // If no username, repoURI and githubKey are required
      return !!data.repoURI && !!data.githubKey
    },
    {
      message:
        'Invalid form configuration: Either provide githubUsername with repoURI, or provide repoURI and githubKey',
    }
  )

export type RepositoryData = z.infer<typeof repositorySchema>
// Custom validation for GitHub repository URLs

const isValidGitHubUrl = (url: string) => {
  // Match HTTPS format: https://github.com/org/repo.git
  const httpsPattern = /^https:\/\/github\.com\/[\w-]+\/[\w.-]+(?:\.git)?$/

  // Match SSH format: git@github.com:org/repo.git
  const sshPattern = /^git@github\.com:[\w-]+\/[\w.-]+(?:\.git)?$/

  return httpsPattern.test(url) || sshPattern.test(url)
}
export const projectSchema = z.object({
  name: z.string().min(1, 'Project name is required'),
  prospectName: z.string().min(1, 'Prospect name is required'),
  prospectWebsite: z.string().refine(
    value => {
      const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i
      // If URL has a protocol, validate the complete URL
      if (value.startsWith('http://') || value.startsWith('https://')) {
        return urlRegex.test(value)
      }

      // For protocol-less URLs, test both the original and with added https://
      return urlRegex.test(value) || urlRegex.test(`https://${value}`)
    },
    {
      message: 'Must be a valid URL (with or without protocol)',
    }
  ),
  techStack: z.string().min(1, 'Tech stack is required'),
  organizationId: z.string().min(1, 'Organization is required'),
  teamId: z.string().min(1, 'Team is required'),
  requirements: z.string().optional(),
})
export type ProjectData = Required<z.infer<typeof projectSchema>>
