export const LOCAL_STORAGE_EMAIL_LOGIN_KEY = 'emailLogin'

export const PROJECT_STATUSES = {
  IN_PROGRESS: 'INPROGRESS',
  READY: 'READY',
  QUEUED: 'QUEUED',
  NOT_STARTED: 'NOTSTARTED',
}

export const ELEMENT_STATUSES = {
  RUNNING: 'RUNNING',
  AWAITING: 'NEW',
  DONE: 'DONE',
  FAILED: 'FAILED',
}

export const ITERATION_STATUSES = {
  AWAITING: 'AWAITING',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  DONE: 'DONE',
  ERRORED: 'ERRORED',
  NEW: 'NEW',
  RUN_REQUESTED: 'RUN_REQUESTED',
  QUEUED: 'QUEUED',
  FAILED: 'FAILED',
}

export const ITERATION_COMMANDS = {
  PAUSE: 'PAUSE',
  RESUME: 'RESUME',
  EXTEND: 'EXTEND',
  RESTART: 'RESTART',
  SUGGESTION: 'SUGGESION',
  ROLLBACK: 'ROLLBACK',
  INPUT_REQUEST: 'INPUT_REQUEST',
  INPUT_ANSWER: 'INPUT_ANSWER',
}

export const STEP_STATUSES = ELEMENT_STATUSES
export const ACTION_STATUSES = ELEMENT_STATUSES

export const PROJECT_STATUSES_LABELS = {
  [PROJECT_STATUSES.IN_PROGRESS]: 'AI is working',
  [PROJECT_STATUSES.READY]: 'Ready for review',
  [PROJECT_STATUSES.QUEUED]: 'Queued',
  [PROJECT_STATUSES.NOT_STARTED]: 'Awaiting prompt',
}

export const PROJECT_STATUSES_COLORS = {
  [PROJECT_STATUSES.IN_PROGRESS]: 'sky',
  [PROJECT_STATUSES.READY]: 'lime',
  [PROJECT_STATUSES.QUEUED]: 'amber',
  [PROJECT_STATUSES.NOT_STARTED]: 'zinc',
}
export const ACL_ROLES = {
  // superadmin, admin, manager, creator, viewer
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  MANAGER: 'manager',
  CREATOR: 'creator',
  VIEWER: 'viewer',
}

export function getStatusColor(status) {
  return PROJECT_STATUSES_COLORS[status] || 'zinc'
}

export const ITERATION_STATUS_TO_COMMAND = {
  [ITERATION_STATUSES.RUNNING]: ITERATION_COMMANDS.PAUSE,
  [ITERATION_STATUSES.PAUSED]: ITERATION_COMMANDS.RESUME,
  [ITERATION_STATUSES.AWAITING]: ITERATION_COMMANDS.PAUSE,
  [ITERATION_STATUSES.ERRORED]: ITERATION_COMMANDS.RESUME,
  [ITERATION_STATUSES.RUN_REQUESTED]: ITERATION_COMMANDS.PAUSE,
  [ITERATION_STATUSES.QUEUED]: ITERATION_COMMANDS.PAUSE,
}

export const GUNSLINGER_JOB_STATUSES = {
  QUEUED: 'QUEUED',
  STARTING: 'STARTING',
  STARTED: 'STARTED',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  STOPPING: 'STOPPING',
  ERROR: 'ERROR',
}

export const TEMPLATE_WIDGET_TYPE = {
  TEXT_SHORT: 'text-short',
  TEXT_LONG: 'text-long',
}
