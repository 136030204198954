import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Textarea } from '@/components/catalyst/textarea'
import { Button } from '@/components/catalyst/button'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import { sendUserCommand } from '@/lib/sendUserCommand'

export default function MRestartFromAction({ action, showUserInput, setShowUserInput }) {
  const [isSendingCommand, setIsSendingCommand] = useState(false)

  const [userInput, setUserInput] = useState('')

  const sendIterationCommand = useCallback(async () => {
    setIsSendingCommand(true)

    await sendUserCommand(action.iterationId, {command: 'RESUME'})
    
    setIsSendingCommand(false)
    setShowUserInput(false)
    setUserInput('')

    analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ROLLBACK_NO_COMMENT, {
      iterationId: action.iterationId,
      elementIndex: action.elementIndex,
      stepIndex: action.stepIndex,
      actionIndex: action.index,
    })
  }, [action, userInput, setShowUserInput])

  return (
    <>
      {showUserInput && (
        <>
          <div className="mb-4 mt-4 w-full border border-dashed border-zinc-200" />
          <div className="flex  flex-col items-center justify-center rounded-md border border-zinc-200 bg-zinc-50 p-4 shadow-inner">
            <Textarea
              className="w-full"
              value={userInput}
              onChange={e => setUserInput(e.target.value)}
              placeholder="Give your hint..."
            />
            <div className="mt-4 flex w-full justify-end">
              <Button
                outline
                className="cursor-pointer"
                disabled={isSendingCommand}
                onClick={() => {
                  setShowUserInput(!showUserInput)
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isSendingCommand}
                className="ml-4 cursor-pointer"
                color="red"
                onClick={sendIterationCommand}
              >
                {isSendingCommand ? 'Working...' : 'Restart from this point'}
              </Button>
            </div>
            <div className="mt-4 flex w-full items-center justify-end">
              <p className="text-xs text-zinc-500">
                This will remove all actions taken after this point and re-run with your suggestion
              </p>
            </div>
          </div>
          <div className="mb-4 mt-4 w-full border border-dashed border-zinc-200" />
        </>
      )}
    </>
  )
}

MRestartFromAction.propTypes = {
  action: PropTypes.object,
}
