import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useState } from 'react'
import { FoldVertical, UnfoldVertical, Copy } from 'lucide-react'
import { useClipboardCopy } from '@/lib/hooks.js'
import { useToast } from '@/components/ui/use-toast.js'

export default function AActionContentTerminal({ content, terminalOutputs, errored = false }) {
  const [showTerminalOutputs, setShowTerminalOutputs] = useState(false)
  const { copy: copyContent } = useClipboardCopy()
  const { toast } = useToast()

  const className = classNames(
    "p-2 rounded-t-sm mt-1 break-all bg-stone-800 group relative px-4 text-sm font-normal font-['DM Mono'] leading-tight",
    terminalOutputs && 'cursor-pointer ',
    !terminalOutputs && 'rounded-b-sm',
    errored && 'bg-red-700 text-zinc-200',
    !errored && 'bg-black text-green-400'
  )

  const handleCopyToClipboard = content => {
    copyContent(
      content,
      () =>
        toast({
          title: 'Copied to clipboard! 🎉',
        }),
      () =>
        toast({
          variant: 'destructive',
          title: 'Failed to copy to clipboard 😔',
        })
    )
  }

  let terminalOutputContent = ''
  if (typeof terminalOutputs === 'string') {
    terminalOutputContent = terminalOutputs
  } else {
    terminalOutputs?.forEach(content => {
      terminalOutputContent += `${content.replace('- stdout:', '').replace('- stderr:', '').replace('- exit_code: 0', '').trimLeft()}`
    })
  }

  return (
    <>
      <div className={className}>
        <span>{content}</span>
        <div
          className="absolute right-3 top-3 z-10 cursor-pointer opacity-0 group-hover:opacity-100"
          onClick={() => handleCopyToClipboard(content)}
        >
          <Copy className="size-4 text-gray-500 hover:text-black" />
        </div>
      </div>
      {!!terminalOutputs && (
        <div className="group relative flex w-full flex-col gap-2">
          <div
            className="absolute right-3 top-3 z-10 cursor-pointer  opacity-0 group-hover:opacity-100"
            onClick={() => handleCopyToClipboard(terminalOutputContent)}
          >
            <Copy className="size-4 text-gray-500 hover:text-black" />
          </div>
          <div
            className={`ftext-sm font-['DM Mono'] flex w-full max-w-full flex-col whitespace-pre-wrap break-all rounded-b-sm bg-black p-4 font-normal leading-tight text-neutral-300 ${!showTerminalOutputs && 'max-h-[6rem] overflow-hidden'}`}
          >
            {terminalOutputContent}
          </div>
          <div
            className="inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded-md bg-stone-100 px-3 py-1.5"
            onClick={() => {
              setShowTerminalOutputs(prev => !prev)
            }}
          >
            {showTerminalOutputs ? (
              <FoldVertical className="relative size-4" />
            ) : (
              <UnfoldVertical className="relative size-4" />
            )}

            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              {showTerminalOutputs ? 'Collapse Output' : 'Expand Output'}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

AActionContentTerminal.propTypes = {
  content: PropTypes.string,
  terminalOutputs: PropTypes.arrayOf(PropTypes.string),
}
