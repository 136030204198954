import PropTypes from 'prop-types'
import { Link } from '@/components/catalyst/link'
import { Fragment, useCallback, useMemo } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import useStore from '@/stores/useStore'
import logo from '@/assets/images/logo.svg'

import { signOut } from '@/services/Authentication'
import { ROUTES } from '@/const/routes'
import { HomeIcon } from '@heroicons/react/20/solid'
import { Avatar } from '@/components/catalyst/avatar'
import { analyticsTrackEvent, analyticsIdentifyReset, ANALYTIC_EVENTS } from '@/services/Analytics'

/**
 * @typedef {{ name: string, current: boolean, href: string }} Breadcrumb - An array of breadcrumb objects.
 * @typedef {Object} OPageHeaderProps
 * @property {{ name: string, current: boolean, href: string }[]} breadcrumbs An array of breadcrumb objects.
 */

/**
 * @param {OPageHeaderProps} props
 * @returns {JSX.Element}
 */
function OBreadcrumbs({ breadcrumbs = [] }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li className="flex min-h-[38px]">
          <div className="flex items-center">
            <Link href={ROUTES.HOME} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="size-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs?.map(page => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              {page.current ? (
                <div
                  className="ml-4 text-sm font-medium text-gray-500 hover:cursor-auto"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </div>
              ) : (
                <Link
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:underline hover:underline-offset-1 "
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

/**
 * @param {OPageHeaderProps} props
 * @returns {JSX.Element}
 */
function OPageHeader({ breadcrumbs = [] }) {
  const user = useStore(state => state.user)
  const handleSignOut = useCallback(async () => {
    await signOut()
    analyticsTrackEvent(ANALYTIC_EVENTS.AUTH_LOGOUT)
    analyticsIdentifyReset()
  }, [])
  const initials = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName[0]}${user.lastName[0]}`
    } else if (user?.firstName) {
      return `${user.firstName[0]}`
    } else if (user?.lastName) {
      return `${user.lastName[0]}`
    }
    return ''
  }, [user])
  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
            'bg-white shadow-sm lg:static lg:overflow-y-visible'
          )
        }
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
                <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                  <div className="flex flex-shrink-0 items-center">
                    <Link href={ROUTES.ROOT}>
                      <img className="h-8 w-auto" src={logo} alt="Mimir" />
                    </Link>
                  </div>
                </div>
                <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                  <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                    <div className="w-full">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MagnifyingGlassIcon
                            className="size-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="search"
                          name="search"
                          className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          placeholder="Search"
                          type="search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button className="relative -mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XMarkIcon className="block size-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block size-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-5 flex-shrink-0">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <Avatar initials={initials} className="size-8 text-xs" />
                        {/* <img className="size-8 rounded-full" src={userImageUrl} alt="" /> */}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <>
                            <p className="block cursor-pointer px-4 py-2 text-sm font-semibold text-gray-700">
                              {user.firstName
                                ? `${user.firstName} ${user?.lastName}`
                                : 'Loading your name...'}
                            </p>
                            <p className="block cursor-pointer border-b border-gray-300 px-4 pb-2 text-xs font-normal text-gray-700">
                              {user.email ? user.email : 'Loading your email...'}
                            </p>
                          </>
                        </Menu.Item>
                        <Menu.Item>
                          <p className="mt-2 block cursor-pointer px-4 py-2 text-sm text-gray-700">
                            Settings
                          </p>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={handleSignOut}
                            className="block cursor-pointer px-4 py-2 text-sm text-gray-700"
                          >
                            Sign out
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <Avatar initials={initials} className="size-10 text-xs" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {user?.firstName ? `${user.firstName} ${user?.lastName}` : 'Loading...'}
                    </div>
                    <div className="text-sm font-medium text-gray-500">{user?.email}</div>
                  </div>
                </div>
                <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                  <div
                    onClick={handleSignOut}
                    className="block cursor-pointer rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  >
                    Sign out
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
      <header className="bg-white shadow-sm">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          <OBreadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </header>
    </>
  )
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {[{ name: string, current: boolean, href: string }]} props.breadcrumbs
 * @param {string} [props.className]
 */
export default function OPageWrapper({ children, breadcrumbs = [], className = '' }) {
  return (
    <div className={className}>
      <OPageHeader breadcrumbs={breadcrumbs} />

      <div className="container mx-auto sm:px-6 lg:px-8">{children}</div>
    </div>
  )
}

OPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
      current: PropTypes.bool,
    })
  ),
  className: PropTypes.string,
}

OPageHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
      current: PropTypes.bool,
    })
  ),
}

OBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
      current: PropTypes.bool,
    })
  ),
}
