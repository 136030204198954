import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'

import { STEP_STATUSES } from '@/const/const'
import { getCommitHashes } from '@/lib/git-utils.js'
import { XOctagon } from 'lucide-react'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import useStore from '@/stores/useStore'
import { isSuperadmin as isSuperAdminFn } from '@/lib/acl-utils'
import { getLLMActionsForIterationElementStepFirebaseFunction } from '@/services/Firebase'
import { useSearchParams } from 'react-router-dom'
import { formatIndex, safeParseInt } from '@/lib/params-utils.js'
import MActionsList from '@/components/molecules/iteration-details/MActionsList.jsx'
import Logo from '@/assets/svg-components/Logo.jsx'
import { ACTION_NAMES } from '@/const/actions.ts'
import colors from 'tailwindcss/colors.js'

const STEP_TYPE_LABELS = {
  research: '📖 Researcher',
  implementation: '🧑‍💻 Implementer',
  troubleshooting: '🧪 Troubleshooter',
  test: '🧪 Tester',
  documentation: '📝 Documenter',
  default: '📝 Agent',
}

export function MStepsListItem({
  step,
  isLast = false,
  expandAllDetails = false,
  filterOutLLMActions = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedStepIndex = safeParseInt(searchParams.get('step'))
  const [showActions, setShowActions] = useState(
    expandAllDetails || step?.status !== 'DONE' || selectedStepIndex === step?.index
  )
  const [showLLMActions, setShowLLMActions] = useState(false)
  const [llmActionsLoading, setLLMActionsLoading] = useState(false)
  const [llmActions, setLLMActions] = useState([])
  const userRoles = useStore(state => state.userRoles)
  // const [showAllActionsInStep, setShowAllActionsInStep] = useState(false)

  const isSuperAdmin = useMemo(() => {
    return isSuperAdminFn(userRoles)
  }, [userRoles])

  const actions = useMemo(() => {
    if (!step?.actions) {
      return null
    }

    let actionsCombined = {}
    if (showLLMActions) {
      actionsCombined = { ...llmActions, ...step?.actions }
    } else {
      actionsCombined = step?.actions
    }

    let actions = Object.entries(actionsCombined).map(([key, value]) => {
      return { actionId: key, ...value, repoURI: step.repoURI }
    })
    if (!isSuperAdmin) {
      actions = actions.filter(action => action.name !== ACTION_NAMES.STEP_DONE)
    }

    actions = actions.sort((a, b) => a.index - b.index)
    // remove llm & hint actions
    // actions = actions.filter(action => action.type !== 'llm' && action.type !== 'hints')

    return actions
  }, [step?.actions, step.repoURI, showLLMActions, llmActions])

  const report = useMemo(() => {
    const stepDoneAction = Object.values(step?.actions)?.find(
      action => action.name === ACTION_NAMES.STEP_DONE
    )
    return stepDoneAction?.args?.report
  }, [step?.actions])

  const actionsWithSTDOUTCombined = useMemo(() => {
    // go over all actions. If action.subtype is `async_function_output`
    // add action.outputs.stdout to the action.terminal_outputs [] where actionId equals action.args.sourceActionId
    // don't mutate the original actions array and don't add async_function_output to filtered

    if (!actions) {
      return []
    }

    const filtered = cloneDeep(actions.filter(action => action.subtype !== 'async_function_output'))
    const asyncFunctionOutputs = actions.filter(
      action => action.subtype === 'async_function_output'
    )

    asyncFunctionOutputs.forEach(asyncFunctionOutput => {
      const action = filtered.find(
        action => action.actionId === asyncFunctionOutput?.args?.sourceActionId
      )
      if (action) {
        if (!action.terminal_outputs) {
          action.terminal_outputs = []
        }
        asyncFunctionOutput?.outputs.forEach(output => {
          action.terminal_outputs.push(output?.stdout || 'Std out missing')
        })
      }
    })
    return filtered
  }, [actions])

  const commitHashes = useMemo(() => {
    return getCommitHashes(actionsWithSTDOUTCombined)
  }, [actionsWithSTDOUTCombined])

  const isDone = useMemo(() => {
    return step.status === STEP_STATUSES.DONE
  }, [step])

  const isAwaiting = useMemo(() => {
    return step.status === STEP_STATUSES.AWAITING
  }, [step])

  const isRunning = useMemo(() => {
    return step.status === STEP_STATUSES.RUNNING
  }, [step])

  const stepAgentLabel = useMemo(() => {
    return STEP_TYPE_LABELS[step?.type] || STEP_TYPE_LABELS['default']
  }, [step?.type])

  const handleToggleShowActions = useCallback(() => {
    if (!showActions) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXPAND_STAGE_STEP, {
        iterationId: step?.iterationId,
        elementIndex: step?.elementIndex,
        stepIndex: step?.index,
        stepStatus: step?.status || 'N/A',
      })
    }
    setShowActions(!showActions)
    setSearchParams(
      prevParams => {
        const newParams = new URLSearchParams(prevParams)
        if (step?.index) {
          newParams.set('step', step.index)
        } else {
          newParams.delete('step')
        }
        newParams.set('element', step.elementIndex)
        newParams.delete('action')
        return newParams
      },
      { replace: false }
    )
  }, [showActions, step])

  useEffect(() => {
    if (showLLMActions && step?.updatedAt) {
      setLLMActionsLoading(true)
      getLLMActionsForIterationElementStepFirebaseFunction({
        iterationId: step?.iterationId,
        elementId: step?.elementId,
        stepId: step?.id,
      }).then(llmActions => {
        setLLMActions(llmActions.data || [])
        setLLMActionsLoading(false)
      })
    }
  }, [showLLMActions, step?.iterationId, step?.elementId, step?.id, step?.updatedAt])

  const [stepStatusComponent, backgroundColor] = useMemo(() => {
    switch (step.status) {
      case STEP_STATUSES.AWAITING:
        return [
          <span key="step-status-done" className="text-zinc-500">
            {formatIndex(step.index)}
          </span>,
          'zinc-50',
        ]
      case STEP_STATUSES.RUNNING:
        return [
          <Logo key="step-running-logo" color={colors.violet[500]} className="size-6" />,
          '[#f8f3fe]',
        ]
      case STEP_STATUSES.DONE:
        return [
          <span key="step-status-done" className="text-green-500 ">
            {formatIndex(step.index)}
          </span>,
          'green-50',
        ]
      case STEP_STATUSES.FAILED:
        return [<XOctagon key="step-status-failed" className="h-6 w-5 text-red-500" />, 'red-50']
      default:
        return [
          <span key="step-status-done" className="text-green-500">
            {formatIndex(step.index)}
          </span>,
          'zinc-50',
        ]
    }
  }, [step.index])

  return (
    <div
      data-index={step?.index}
      className="flex w-full items-stretch justify-start gap-6 py-1 pl-1 pr-6"
    >
      <div
        className={`flex w-16 flex-shrink-0  items-center justify-center self-stretch rounded-md bg-${backgroundColor}`}
      >
        <div className="font-['PP Supply Sans']  flex items-center justify-center text-center text-2xl font-normal leading-9">
          {stepStatusComponent}
        </div>
      </div>
      <div className="flex w-full shrink grow basis-0 flex-col items-start justify-start gap-6">
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-1 pt-5">
          <div className="self-stretch font-['Inter'] text-base font-medium leading-normal text-stone-900">
            {step.name}
          </div>
          <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-500">
            {step?.summary || (step?.description ?? '')}
          </div>
        </div>

        {report && (
          <div className="inline-flex flex-col items-start justify-center gap-2 rounded-md bg-stone-100 px-4 py-3">
            <div className="inline-flex items-center justify-between self-stretch">
              <div className="font-['DM Mono'] text-sm font-medium leading-tight text-stone-500">
                Report
              </div>
              <div className="flex items-center justify-start gap-[9.60px]">
                <div className="relative size-4" />
              </div>
            </div>
            <div className="self-stretch font-['Inter'] text-sm font-normal leading-tight text-stone-500">
              {report}
            </div>
          </div>
        )}

        <div className="flex  flex-col items-start justify-start gap-4 self-stretch">
          <MActionsList
            actions={actionsWithSTDOUTCombined}
            expandAllDetails={expandAllDetails}
            showAllActionsInStep={showActions}
          />
          {actionsWithSTDOUTCombined.length > 3 && (
            <button
              className="inline-flex h-9 items-center justify-center gap-2 self-stretch rounded-md border border-stone-200 bg-white px-3 py-2"
              onClick={() => {
                handleToggleShowActions()
              }}
            >
              <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                {showActions
                  ? `Hide all ${actionsWithSTDOUTCombined.length} actions`
                  : `Show all ${actionsWithSTDOUTCombined.length} actions`}
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default function MStepsList({
  steps,
  expandAllDetails = false,
  filterOutLLMActions = true,
}) {
  const [searchParams] = useSearchParams()
  const selectedStepIndex = safeParseInt(searchParams.get('step'))
  const listRef = useRef(null)

  useEffect(() => {
    if (searchParams.get('action') != null) {
      return
    }
    if (selectedStepIndex !== null && listRef.current) {
      const selectedElement = listRef.current.querySelector(`[data-index="${selectedStepIndex}"]`)
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [])

  return (
    <div ref={listRef} role="list" className="">
      {steps?.map((step, index) => (
        <MStepsListItem
          key={step.stepId}
          step={step}
          isLast={index === steps.length - 1}
          expandAllDetails={expandAllDetails}
          filterOutLLMActions={filterOutLLMActions}
        />
      ))}
    </div>
  )
}

MStepsList.propTypes = {
  steps: PropTypes.array,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}

MStepsListItem.propTypes = {
  step: PropTypes.object,
  isLast: PropTypes.bool,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}
