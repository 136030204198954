import { useState } from 'react'
import PropTypes from 'prop-types'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/16/solid'

import { Button } from '@/components/catalyst/button'
import { Avatar } from '@/components/catalyst/avatar'

import MProjectCostSummaryCard from '@/components/molecules/project-details/MProjectCostSummaryCard'
import MUpdateOrganizationDialog from '@/components/molecules/MUpdateOrganizationDialog.jsx'

function MOrganizationHeaderCompact({ name = 'Organization name', onExpand = () => {} }) {
  return (
    <div className="px-2 py-6 lg:px-24 lg:py-12">
      <div className=" flex items-center justify-between overflow-hidden rounded-lg bg-white shadow-md">
        <h2 className="sr-only" id="profile-overview-title">
          Project Header
        </h2>
        <div className="flex items-center justify-start space-x-4 p-4">
          {/* <img className="mx-auto size-20 rounded-full" src={user.imageUrl} alt="" /> */}
          <Avatar src={`/organization.svg`} className="size-6" />

          <div className="col-span-2 flex flex-col items-start justify-center">
            <p className="font-mono text-xs font-semibold text-zinc-500">Organization</p>
            <div className="flex items-center">
              <p className="text-base font-bold text-gray-900">{name}</p>
              <div className="px-2">
                <Button plain onClick={onExpand} className="cursor-pointer">
                  <ChevronDownIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MOrganizationHeaderCompact.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  onExpand: PropTypes.func,
}

/**
 * @param {object} props
 * @param {string} props.image
 * @param {string} props.teamName
 * @param {string} props.projectName
 * @param {string} props.organizationName
 * @param {number} props.inputTokens
 * @param {number} props.outputTokens
 * @param {number} props.elapsedTime
 * @param {boolean} props.areAllIterationsDone
 * @param {number} props.cost
 * @returns {JSX.Element}
 */
export default function MOrganizationHeader({ organization }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  if (!isExpanded) {
    return (
      <MOrganizationHeaderCompact
        name={organization.name}
        onExpand={() => {
          setIsExpanded(true)
        }}
      />
    )
  }
  return (
    <div className="px-2 py-6 lg:px-24 lg:py-12">
      <div className="overflow-hidden rounded-lg bg-white shadow-xl">
        <h2 className="sr-only" id="profile-overview-title">
          Organization Header
        </h2>
        <div className=" bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                {/* <img className="mx-auto size-20 rounded-full" src={user.imageUrl} alt="" /> */}
                <Avatar src={`/organization.svg`} className="size-20" />
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <div className="flex items-center">
                  <p className="text-xl font-bold text-gray-900 sm:text-2xl">{organization.name}</p>
                  <div className="px-2">
                    <Button
                      plain
                      onClick={() => {
                        setIsExpanded(false)
                      }}
                      className="cursor-pointer"
                    >
                      <ChevronUpIcon />
                    </Button>{' '}
                  </div>
                </div>

                <div className="flex items-center">
                  <p className="text-sm font-medium text-gray-600">{organization.description}</p>
                </div>
                <div className="pt-5">
                  <Button
                    outline
                    onClick={() => {
                      setIsUpdateDialogOpen(true)
                    }}
                  >
                    Edit organization
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isUpdateDialogOpen && (
        <MUpdateOrganizationDialog
          initialValues={organization}
          onClose={() => setIsUpdateDialogOpen(false)}
          open={isUpdateDialogOpen}
        />
      )}
    </div>
  )
}

MOrganizationHeader.propTypes = {
  organization: PropTypes.object,
}
