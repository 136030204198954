import { ITERATION_COMMANDS } from '@/const/const'
import { createIterationCommandFirebaseFunction } from '@/services/Firebase.ts'
import { z } from 'zod'

const ExtendCommand = z.object({
  command: z.literal(ITERATION_COMMANDS.EXTEND),
  commandArgs: z.object({
    prompt: z.string(),
    iteration_id: z.string(),
    blueprint_id: z
      .string()
      .nullish()
      .default(null)
      .optional()
      .transform(value => (value === '' ? null : value)),
  }),
})

const ResumeCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.RESUME),
  commandArgs: z.never().optional(),
})

const PauseCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.PAUSE),
  commandArgs: z.never().optional(),
})

const RestartCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.RESTART),
  commandArgs: z.never().optional(),
})

const SuggestionCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.SUGGESTION),
  commandArgs: z.object({
    suggestion: z.string(),
  }),
})

const RollbackCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.ROLLBACK),
  commandArgs: z.object({
    iteration_id: z.string(),
    element_index: z.number().positive().optional(),
    step_index: z.number().positive().optional(),
    action_index: z.number().positive().optional(),
  }),
})

const InputAnswerCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.INPUT_ANSWER),
  commandArgs: z.object({
    request_id: z.string(),
    requests: z.array(
      z.object({
        request: z.string(),
        type: z.enum(['text', 'secret']),
        user_value: z.string(),
      })
    ),
  }),
})

const UserInputCommandSchema = z.discriminatedUnion('command', [
  ExtendCommand,
  ResumeCommandSchema,
  PauseCommandSchema,
  RestartCommandSchema,
  InputAnswerCommandSchema,
  SuggestionCommandSchema,
  RollbackCommandSchema,
])

type UserInputCommand = z.infer<typeof UserInputCommandSchema>

export async function sendUserCommand(iterationId: string, command: UserInputCommand) {
  const payload = {
    iterationId: iterationId,
    dontStartGunslinger: !SHOULD_START_GUNSLINGER,
    ...UserInputCommandSchema.parse(command),
  }

  return createIterationCommandFirebaseFunction(payload)
}

const SHOULD_START_GUNSLINGER = !window?.location?.host?.startsWith('old.')
