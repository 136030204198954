import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  Link,
  Navigate,
  Route,
  Routes,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import useStore from '@/stores/useStore'

import { auth } from '@/services/Firebase'
import { ROUTES } from '@/const/routes'

import PLoginWithEmailLinkConfirmation from '@/components/pages/PLoginWithEmailLinkConfirmation'
import PLogin from '@/components/pages/v2/PLogin/PLogin.v2'
import { POnboarding } from '@/components/pages/v2/POnboarding/POnboarding'

import PHome2 from '@/components/pages/PHome2.jsx'
import PRojectDetails from '@/components/pages/PProjectDetails.jsx'
import POrganizationDetails from '@/components/pages/POrganizationDetails'
import PTeamDetails from '@/components/pages/PTeamDetails'
import PUsers from '@/components/pages/PUsers.tsx'
import PManageVMs from '@/components/pages/PManageVMs'
import PIterationFeedback from './components/pages/PIterationFeedback'

import {
  ANALYTIC_EVENTS,
  analyticsIdentify,
  analyticsIdentifyReset,
  analyticsIsOptedInCapturing,
  analyticsOpInCapturing,
  analyticsOptOutCapturing,
  analyticsTrackEvent,
} from './services/Analytics'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import {
  Building2,
  ChevronRight,
  FolderKanban,
  LayoutDashboard,
  Search,
  Settings,
  User,
  Users,
  Zap,
  Plus,
} from 'lucide-react'
import MCreateIterationDialog from '@/components/molecules/iteration-details/CreateIteration/MCreateIterationDialog.jsx'
import MCreateProjectDialog from '@/components/molecules/project-details/CreateProject/MCreateProjectDialog.tsx'
import { POnboardingBuilding } from './components/pages/v2/POnboardingBuilding/POnboardingBuilding'
import { OnboardingProvider } from '@/components/pages/v2/POnboarding/OnboardingProvider.tsx'
import PHome from '@/components/pages/PHome.jsx'
import { signOut } from '@/services/Authentication.js'

function LoginRouter() {
  return (
    <>
      <Routes>
        <Route path={ROUTES.ROOT} element={<PLogin />} />
        <Route
          exact
          path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION}
          element={<PLoginWithEmailLinkConfirmation />}
        />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <ScrollRestoration />
    </>
  )
}

function OnboardingRouter() {
  return (
    <>
      <OnboardingProvider>
        <Routes>
          <Route path={ROUTES.ONBOARDING_BUILDING} element={<POnboardingBuilding />} />
          <Route path={ROUTES.ONBOARDING} element={<POnboarding />} />
          <Route path="*" element={<Navigate replace to={ROUTES.ONBOARDING} />} />
        </Routes>
      </OnboardingProvider>

      <ScrollRestoration />
    </>
  )
}

function MMainAnalytics() {
  const [authUser] = useAuthState(auth)
  const appOpenEventSent = useRef(false)
  const organizations = useStore(state => state.organizations)
  const user = useStore(state => state.user)
  const userRoles = useStore(state => state.userRoles)
  const location = useLocation()

  useEffect(() => {
    if (authUser?.uid && user && organizations.length > 0 && userRoles) {
      const isExternal = user?.isExternal ? true : false

      // if user is external, we need to track the user
      // if user is internal, we need to opt out of tracking
      if (isExternal) {
        if (!analyticsIsOptedInCapturing()) {
          analyticsOpInCapturing()
        }

        const orgIds = organizations.map(org => org.id)
        analyticsIdentify(authUser?.uid, {
          name: `${user?.firstName} ${user?.lastName}`,
          userId: authUser?.uid,
          isExternal,
          organizations: orgIds,
        })
        if (!appOpenEventSent.current) {
          analyticsTrackEvent(ANALYTIC_EVENTS.APP_OPEN)
          appOpenEventSent.current = true
        }
      } else {
        if (analyticsIsOptedInCapturing()) {
          analyticsOptOutCapturing()
        }
      }
    }
  }, [user, organizations, userRoles, authUser?.uid])

  useEffect(() => {
    analyticsTrackEvent('$pageview')
  }, [location])

  return null
}

function App() {
  const [authUser, isAuthUserLoading] = useAuthState(auth)
  const loadInitialData = useStore(state => state.loadInitialData)
  const user = useStore(state => state.user)
  const isAfterInitialLoad = useStore(state => state.isAfterInitialLoad)
  const navigate = useNavigate()

  const isUserLoggedOut = useMemo(() => {
    return Boolean(!authUser && !isAuthUserLoading)
  }, [authUser, isAuthUserLoading])

  const isOnboarding = user?.email && user.isOnboarding

  // Load initial data when auth is ready and user is logged in
  // Unsubscribe from all listeners when component is unmounted
  useEffect(() => {
    if (authUser?.uid) {
      loadInitialData(authUser.uid)
    }
    return () => {
      useStore.getState().unsubscribeFromAllListeners()
    }
  }, [authUser?.uid, loadInitialData])

  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  if (isAuthUserLoading) {
    return null
  }

  if (isUserLoggedOut) {
    return <LoginRouter />
  }

  if (!user?.email) {
    return null
  }

  if (isOnboarding) {
    return <OnboardingRouter />
  }

  const redirectToProjectUrl = user.projectToRedirectAfterOnboarding
    ? `/projects/${user.projectToRedirectAfterOnboarding}`
    : ROUTES.HOME

  if (!isSuperAdmin) {
    return (
      <Layout>
        <Routes>
          <Route
            path={ROUTES.ONBOARDING_BUILDING}
            element={<Navigate replace to={redirectToProjectUrl} />}
          />
          <Route
            path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION}
            element={<Navigate replace to="/" />}
          />
          <Route path={ROUTES.HOME} exact element={<PHome />} />
          <Route path={ROUTES.PROJECTS} exact element={<PHome />} />
          <Route path={ROUTES.PROJECT_DETAILS} element={<PRojectDetails />} />
          <Route path="*" element={<Navigate replace to={ROUTES.HOME} />} />
        </Routes>
        {/*<ScrollRestoration />*/}
        <MMainAnalytics />
      </Layout>
    )
  }

  return (
    <Layout>
      <Routes>
        <Route
          path={ROUTES.ONBOARDING_BUILDING}
          element={<Navigate replace to={redirectToProjectUrl} />}
        />
        <Route path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION} element={<Navigate replace to="/" />} />
        <Route path={ROUTES.HOME} exact element={<PHome />} />
        <Route path={ROUTES.DASHBOARD} exact element={<PHome2 />} />
        <Route path={ROUTES.PROJECTS} exact element={<PHome />} />
        <Route path={ROUTES.PROJECT_DETAILS} element={<PRojectDetails />} />
        <Route path={ROUTES.ORGANIZATION_DETAILS} element={<POrganizationDetails />} />
        <Route path={ROUTES.TEAM_DETAILS} element={<PTeamDetails />} />
        <Route path={ROUTES.MANAGE_VMS} element={<PManageVMs />} />
        <Route path={ROUTES.ITERATION_FEEDBACK} element={<PIterationFeedback />} />
        <Route path={ROUTES.USERS} element={<PUsers />} />
        <Route path="*" element={<Navigate replace to={ROUTES.HOME} />} />
      </Routes>
      {/*<ScrollRestoration />*/}
      <MMainAnalytics />
    </Layout>
  )
}

function Sidebar({ isExpanded, setIsExpanded }) {
  const location = useLocation()

  const [isCreateProjectDialogOpen, setIsCreateProjectDialogOpen] = useState(false)
  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)

  const handleSignOut = useCallback(async () => {
    await signOut()
    analyticsTrackEvent(ANALYTIC_EVENTS.AUTH_LOGOUT)
    analyticsIdentifyReset()
  }, [])

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  const toggleSidebar = () => setIsExpanded(!isExpanded)

  let menuItems
  if (isSuperAdmin) {
    menuItems = [
      { icon: LayoutDashboard, label: 'Dashboard', path: ROUTES.DASHBOARD },
      { icon: FolderKanban, label: 'Projects', path: ROUTES.PROJECTS },
      { icon: Users, label: 'Team', path: ROUTES.TEAM_DETAILS },
      { icon: Building2, label: 'Organization', path: ROUTES.ORGANIZATION_DETAILS },
      { icon: Zap, label: 'Manage VMs', path: ROUTES.MANAGE_VMS },
      { icon: Settings, label: 'Iteration Feedback', path: ROUTES.ITERATION_FEEDBACK },
      { icon: Users, label: 'Users', path: ROUTES.USERS },
    ]
  } else {
    menuItems = [{ icon: FolderKanban, label: 'Projects', path: ROUTES.PROJECTS }]
  }

  return (
    <aside
      className={cn(
        'flex flex-col bg-gray-100 text-gray-800 transition-all duration-300',
        isExpanded ? 'w-64 p-4' : 'w-16 p-2'
      )}
    >
      <div className="mb-6 flex items-center justify-center">
        {isExpanded ? (
          <>
            <h1 className="text-xl font-semibold">proofs</h1>
            <div className="ml-auto">
              <Search className="size-5 text-gray-500" />
            </div>
          </>
        ) : (
          <div className="flex size-8 items-center justify-center rounded-full bg-gray-300">
            <span className="font-bold text-gray-600">P</span>
          </div>
        )}
      </div>

      {isExpanded && (
        <div className="mb-6">
          <Input type="text" placeholder="Search" className="w-full" />
        </div>
      )}

      {/*<div*/}
      {/*  className=" mb-3 flex h-10 cursor-pointer items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2"*/}
      {/*  onClick={() => setIsCreateIterationDialogOpen(true)}*/}
      {/*>*/}
      {/*  <div className=" flex size-4 items-center justify-center ">*/}
      {/*    <Plus className="text-stone-50" />*/}
      {/*  </div>*/}
      {/*  {isExpanded && (*/}
      {/*    <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-50">*/}
      {/*      New Project old*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}

      <div
        className=" mb-3 flex h-10 cursor-pointer items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2"
        onClick={() => setIsCreateProjectDialogOpen(true)}
      >
        <div className=" flex size-8 items-center justify-center ">
          <Plus className="text-stone-50" />
        </div>
        {isExpanded && (
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-50">
            New Project
          </div>
        )}
      </div>

      <nav className="flex-grow">
        <ul className="space-y-2">
          {menuItems.map((item, index) => (
            <li key={index}>
              <Button
                variant="ghost"
                className={cn(
                  'w-full justify-center text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                  isExpanded ? 'justify-start px-4' : 'px-2',
                  location.pathname === item.path && 'relative bg-red-100 text-red-600'
                )}
                asChild
              >
                <Link to={item.path}>
                  {location.pathname === item.path && (
                    <div className="absolute bottom-0 left-0 top-0 w-1 bg-red-600" />
                  )}
                  <item.icon className="size-5 flex-shrink-0" />
                  {isExpanded && <span className="ml-2">{item.label}</span>}
                </Link>
              </Button>
            </li>
          ))}
        </ul>
      </nav>
      <div className="mt-auto space-y-2">
        <Button
          variant="ghost"
          onClick={handleSignOut}
          className={cn(
            'w-full justify-center text-gray-600 hover:bg-gray-200 hover:text-gray-900',
            isExpanded ? 'justify-start px-4' : 'px-2'
          )}
        >
          <User className="size-5 flex-shrink-0" />
          {isExpanded && <span className="ml-2">Sign out</span>}
        </Button>
        <Button
          variant="ghost"
          onClick={toggleSidebar}
          className={cn(
            'w-full text-gray-600 hover:bg-gray-200 hover:text-gray-900',
            isExpanded ? 'justify-start px-4' : 'justify-center px-2'
          )}
        >
          <ChevronRight className={cn('size-5 transition-transform', isExpanded && 'rotate-180')} />
          {isExpanded && <span className="ml-2">Collapse</span>}
        </Button>
      </div>
      {/*{isCreateIterationDialogOpen && (*/}
      {/*  <MCreateIterationDialog*/}
      {/*    key={'-create-iteration-dialog'}*/}
      {/*    isOpen={isCreateIterationDialogOpen}*/}
      {/*    onClose={() => {*/}
      {/*      setIsCreateIterationDialogOpen(false)*/}
      {/*    }}*/}
      {/*    onIterationCreated={data => {*/}
      {/*      console.log('Response from onIterationCreated: ', data)*/}
      {/*      navigate(`/projects/${data.projectId}?iteration=${data.iterationId}`)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {isCreateProjectDialogOpen && (
        <MCreateProjectDialog
          key={'-create-project-dialog'}
          isOpen={isCreateProjectDialogOpen}
          onClose={() => {
            setIsCreateProjectDialogOpen(false)
          }}
          onProjectCreated={data => {}}
        />
      )}
    </aside>
  )
}

function Layout({ children }) {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <main className="flex flex-1 justify-center overflow-y-auto bg-gray-100">{children}</main>
    </div>
  )
}

export default App
