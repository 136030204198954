import { useMemo } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { Duration } from 'luxon'
import {
  CpuChipIcon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  ServerStackIcon,
} from '@heroicons/react/24/outline'
/**
 * @param {object} props
 * @param {string} props.className
 * @param {number} props.inputTokens
 * @param {number} props.outputTokens
 * @param {number} props.elapsedTime
 * @param {number} props.cost
 * @param {boolean} props.areAllIterationsDone
 * @returns {JSX.Element}
 */
export default function MProjectCostSummaryCard({
  className = '',
  inputTokens = 0,
  outputTokens = 0,
  elapsedTime = 0,
  cost = 0,
  areAllIterationsDone = true,
}) {
  const usedTimeInHumanLanguage = useMemo(() => {
    const minutesLabel = Duration.fromMillis(elapsedTime * 1000).toFormat("m'm' ss's'")
    return minutesLabel
  }, [elapsedTime])
  const [tokensInUsedFormatted, tokensOutUsedFormatted] = useMemo(() => {
    const browserLocale = navigator.language
    const tokensIn = Intl.NumberFormat(browserLocale).format(inputTokens)
    const tokensOut = Intl.NumberFormat(browserLocale).format(outputTokens)
    return [tokensIn, tokensOut]
  }, [inputTokens, outputTokens])
  return (
    <div className={`${className}`}>
      <h2 className="sr-only">Project Cost summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap pb-6">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">Project Cost</dt>
            <dd className="mt-1 font-mono text-base font-semibold leading-6 text-gray-900">
              ${cost}
            </dd>
          </div>
          <div className="flex flex-col items-end justify-end px-6">
            <div className="flex w-full items-center justify-end pb-2">
              <CpuChipIcon className="size-5" />
            </div>

            <dt className="sr-only">Status</dt>
            {areAllIterationsDone ? (
              <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                All iterations completed
              </dd>
            ) : (
              <dd className="inline-flex animate-pulse items-center rounded-md bg-sky-50 px-2 py-1 text-xs font-medium text-sky-700 ring-1 ring-inset ring-sky-600/20">
                Iterations running
              </dd>
            )}
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Input Tokens Used</span>
              <ChatBubbleBottomCenterTextIcon
                className="h-6 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>Input Tokens</span>{' '}
              <span className="text-right font-mono text-gray-600">{tokensInUsedFormatted}</span>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Output Tokens Used</span>
              <ChatBubbleLeftRightIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>Output Tokens</span>{' '}
              <span className="text-right font-mono text-gray-600">{tokensOutUsedFormatted}</span>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Computing Time Used</span>
              <ServerStackIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span className="flex-shrink">Computing Time</span>{' '}
              <span className="flex-grow text-right font-mono text-gray-600">
                {usedTimeInHumanLanguage}
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

MProjectCostSummaryCard.propTypes = {
  className: PropTypes.string,
  isDone: PropTypes.bool,
  inputTokens: PropTypes.number,
  outputTokens: PropTypes.number,
  elapsedTime: PropTypes.number,
  cost: PropTypes.number,
  areAllIterationsDone: PropTypes.bool,
}
