import React from 'react'
import { Button } from '@/components/ui/button'
import { PlusIcon } from 'lucide-react'
import AddUserDialog from '@/components/molecules/users/AddUserDialog'

export default function Users() {
  const [addUserModalOpened, setAddUserModalOpened] = React.useState(false)

  const handleAddUser = () => {
    setAddUserModalOpened(true)
  }

  const handleSubmit = () => {
    setAddUserModalOpened(false)
  }

  return (
    <>
      <div className="flex w-[800px] flex-col gap-8 py-8">
        <div className="font-['PP Supply Sans'] text-3xl font-normal leading-10 text-stone-900">
          Users
        </div>
        <Button className="w-32" onClick={handleAddUser}>
          <PlusIcon className="size-5" /> Add user
        </Button>
      </div>

      <AddUserDialog
        isOpen={addUserModalOpened}
        onClose={() => setAddUserModalOpened(false)}
        onSubmit={handleSubmit}
      />
    </>
  )
}
