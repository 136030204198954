import ReactMarkdown from 'react-markdown'
import rehypeSanitize from 'rehype-sanitize'

export function MarkdownContent(props: { detailsContent: any }) {
  return (
    <div className="prose w-full max-w-full">
      <ReactMarkdown
        className="[&_pre]:!overflow-x-auto [&_pre]:whitespace-pre-wrap"
        rehypePlugins={[rehypeSanitize]}
      >
        {props.detailsContent}
      </ReactMarkdown>
    </div>
  )
}
