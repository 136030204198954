import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import classNames from 'classnames'

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FaceFrownIcon } from '@heroicons/react/24/outline'

import {
  BadgeCheck,
  Construction,
  Fingerprint,
  GitBranch,
  GitPullRequestArrow,
  PlaneTakeoff,
  Rocket,
  Eye,
  Terminal,
  MonitorDot,
  CalendarPlus,
  CalendarCheck2,
  UserPlus,
  MessageCircleX,
} from 'lucide-react'

import { Combobox, Dialog, Transition } from '@headlessui/react'

import { Link } from '@/components/catalyst/link'

import { Badge } from '@/components/catalyst/badge'
import { Button } from '@/components/catalyst/button'
import MNewIterationDialog from '@/components/molecules/iteration-details/MNewIterationDialog'

import { ITERATION_STATUSES, GUNSLINGER_JOB_STATUSES } from '@/const/const'
import useStore from '@/stores/useStore'
import { convertGitHubSSHToHTTPS, hasValidBranch, hasValidCommitHashes } from '@/lib/git-utils.js'

import { isSuperadmin } from '@/lib/acl-utils'

import MStopVMButton from '@/components/molecules/iteration-details/MStopVMButton'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import OIterationFeedbackDialog from '@/components/organisms/OIterationFeedbackDialog'
import MCreateIterationDialog from '@/components/molecules/iteration-details/CreateIteration/MCreateIterationDialog.jsx'

const HOW_LONG_TO_WAIT_WITH_FEEDBACK = 10_000

function generateGCPLoggingLink(filters) {
  // We do that so the project can easily change from the envs
  const gcpProjectId = import.meta.env.VITE_GCP_LOGS_PROJECT_ID
  const baseUrl = 'https://console.cloud.google.com/logs/query'
  const queryParts = Object.entries(filters).map(([key, value]) => `${key}="${value}"`)
  const query = queryParts.join(' AND ')
  const encodedQuery = encodeURIComponent(query)
  const link = `${baseUrl};query=${encodedQuery}?project=${gcpProjectId}`
  return link
}

export default function MIterationsPicker({
  iterations,
  activeIterationId,
  onActiveSelected = iterationId => {
    console.log('Selected iteration', iterationId)
  },
  isLoading = false,
  onIterationCreated = () => {
    console.log('Default onIterationCreated function')
  },
}) {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [isNewIterationDialogOpen, setIsNewIterationDialogOpen] = useState(false)
  const [isCreateIterationDialogOpen, setIsCreateIterationDialogOpen] = useState(false)
  const activeIterationStatus = useStore(state => state.activeIterationStatus)
  const userRoles = useStore(state => state.userRoles)
  const user = useStore(state => state.user)
  const [optimisticVMStatus, setOptimisticVMStatus] = useState(null)
  const vmStatusOnStopped = useRef(null)

  const [loadedIterationTime, setLoadedIterationTime] = useState(Date.now())
  const [iterationFeedbackDismissed, setIterationFeedbackDismissed] = useState(true)
  const [forceDisplayFeedback, setForceDisplayFeedback] = useState(false)

  const filteredIterations = useMemo(() => {
    return iterations?.filter(iteration => {
      return (
        iteration?.id?.toLowerCase().includes(query.toLowerCase()) ||
        iteration?.prompt?.toLowerCase().includes(query.toLowerCase()) ||
        iteration?.createdBy?.toLowerCase().includes(query.toLowerCase())
      )
    })
  }, [query, iterations])

  const iterationsGroupedByDate = useMemo(() => {
    const grouped = filteredIterations?.reduce((acc, iteration) => {
      const date = DateTime.fromJSDate(iteration?.createdAt?.toDate()).toISODate()
      acc[date] = acc[date] || []
      acc[date].push(iteration)
      return acc
    }, {})

    return Object.fromEntries(Object.entries(grouped).sort(([a], [b]) => b.localeCompare(a)))
  }, [filteredIterations])

  const selectedIteration = useMemo(() => {
    return iterations?.find(iteration => iteration?.id === activeIterationId)
  }, [activeIterationId, iterations])

  const previewUrl = getPreviewUrl(selectedIteration?.preview_urls)

  useEffect(() => {
    const down = e => {
      if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen(open => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  const handleIterationSelected = useCallback(
    selectedIteration => {
      onActiveSelected(selectedIteration?.id)
      setOpen(false)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_SWITCH_ITERATION, {
        newIterationId: selectedIteration?.id,
        projectId: selectedIteration?.projectId,
        teamId: selectedIteration?.teamId,
        organizationId: selectedIteration?.organizationId,
        status: selectedIteration?.status,
        vmStatus: selectedIteration?.vmStatus,
      })
      setLoadedIterationTime(Date.now())
    },
    [onActiveSelected]
  )

  const [iterationStatus, statusColor, statusIcon] = useMemo(() => {
    switch (activeIterationStatus) {
      case ITERATION_STATUSES.AWAITING:
        return [
          'Awaiting',
          'zinc',
          <PlaneTakeoff key="planetakeoff-icon" className="h-6 w-5 text-zinc-700" />,
        ]
      case ITERATION_STATUSES.RUNNING:
        return ['Running', 'blue', <Rocket key="plane-icon" className="h-6 w-5 text-blue-700" />]
      case ITERATION_STATUSES.PAUSED:
        return [
          'Paused',
          'yellow',
          <Construction key="pause-icon" className="h-6 w-5 text-yellow-600" />,
        ]
      case ITERATION_STATUSES.DONE:
        return [
          'Done',
          'green',
          <BadgeCheck key="planelanding-icon" className="h-6 w-5 text-emerald-600" />,
        ]
      case ITERATION_STATUSES.FAILED:
        return [
          'Failed',
          'red',
          <MessageCircleX key="failed-icon" className="h-6 w-5 text-red-600" />,
        ]
      default:
        return [
          activeIterationStatus,
          'zinc',
          <Rocket key="plane-icon" className="h-6 w-5 text-sky-700" />,
        ]
    }
  }, [activeIterationStatus])

  const logsUrl = useMemo(() => {
    if (selectedIteration == null) {
      return generateGCPLoggingLink({})
    }

    const filters = {
      'jsonPayload.organization_id': selectedIteration?.organizationId,
      'jsonPayload.project_id': selectedIteration?.projectId,
      'jsonPayload.iteration_id': selectedIteration?.id,
    }
    return generateGCPLoggingLink(filters)
  }, [selectedIteration])

  const shouldShowLogsUrl = isSuperadmin(userRoles) && logsUrl

  // This one is a bit tricky, we need to reset the optimistic status when the real status changes
  // we need the ref to check only the changes to the vmStatus, not other iteration updates
  useEffect(() => {
    if (vmStatusOnStopped.current !== selectedIteration?.vmStatus) {
      setOptimisticVMStatus(null)
      vmStatusOnStopped.current = null
    }
  }, [selectedIteration?.vmStatus])

  const handleVMStopped = useCallback(() => {
    setOptimisticVMStatus(GUNSLINGER_JOB_STATUSES.STOPPING)
    vmStatusOnStopped.current = selectedIteration?.vmStatus
  }, [selectedIteration?.vmStatus])

  const vmStatus = useMemo(() => {
    if (optimisticVMStatus) {
      return optimisticVMStatus
    } else {
      return selectedIteration?.vmStatus
    }
  }, [optimisticVMStatus, selectedIteration])

  const handleDismissFeeedbackDialog = useCallback(() => {
    setIterationFeedbackDismissed(true)
  }, [])

  const shouldAllowFeedback = useMemo(() => {
    return user &&
      user?.isExternal &&
      selectedIteration?.status === ITERATION_STATUSES.DONE &&
      !selectedIteration?.isFeedbackCaptured
      ? true
      : false
  }, [selectedIteration?.isFeedbackCaptured, selectedIteration?.status, user])

  // useEffect(() => {
  //   if (!shouldAllowFeedback) {
  //     return
  //   } else {
  //     const interval = setInterval(() => {
  //       if (Date.now() - loadedIterationTime > HOW_LONG_TO_WAIT_WITH_FEEDBACK) {
  //         setForceDisplayFeedback(true)
  //       }
  //     }, 10000)
  //     return () => clearInterval(interval)
  //   }
  // }, [loadedIterationTime, shouldAllowFeedback])

  const showFeedbackDialog = useMemo(() => {
    if (!iterationFeedbackDismissed && shouldAllowFeedback) {
      return true
    }
    return false
  }, [iterationFeedbackDismissed, shouldAllowFeedback])

  const getUrlFromText = useCallback(text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const urls = text.match(urlRegex)
    return urls
  }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div className="relative isolate w-full border-b pb-8">
        <div className="mt-4 grid w-full max-w-full grid-cols-1 items-center gap-y-6 sm:gap-y-0 lg:grid-cols-12">
          <div className="relative col-span-6 rounded-lg bg-white p-8 shadow-md ring-1 ring-gray-900/10 sm:p-10">
            <h3 className="font-mono text-lg font-semibold leading-7 text-zinc-900">
              Iteration details
            </h3>

            <ul role="list" className="mt-8 space-y-4 font-sans text-sm leading-6 text-gray-700">
              <li className="flex w-full justify-between gap-x-3 border-b border-zinc-100 pb-3">
                <div className="flex space-x-2">
                  <Fingerprint className="h-6 w-5 flex-none text-zinc-700" aria-hidden="true" />
                  <div>Iteration Id</div>
                </div>
                <div className="font-mono font-bold">{selectedIteration?.id}</div>
              </li>
              <li className="flex w-full justify-between gap-x-3 border-b border-zinc-100 pb-3">
                <div className="flex space-x-2">
                  <UserPlus className="h-6 w-5 flex-none text-zinc-700" aria-hidden="true" />
                  <div>Created by</div>
                </div>
                <div className="font-mono font-bold">{selectedIteration?.createdBy || 'N/A'}</div>
              </li>
              <li className="flex w-full justify-between gap-x-3 border-b border-zinc-100 pb-3">
                <div className="flex space-x-2">
                  <CalendarPlus className="h-6 w-5 flex-none text-zinc-700" aria-hidden="true" />
                  <div>Created on</div>
                </div>
                <div className="font-mono font-bold">
                  {DateTime.fromJSDate(selectedIteration?.createdAt?.toDate())?.toFormat(
                    'LLL d, yyyy h:mm a'
                  ) || 'Loading...'}
                </div>
              </li>
              <li className="flex w-full justify-between gap-x-3 border-b border-zinc-100 pb-3">
                <div className="flex space-x-2">
                  <CalendarCheck2 className="h-6 w-5 flex-none text-zinc-700" aria-hidden="true" />
                  <div>Last activity</div>
                </div>
                <div className="font-mono font-bold">
                  {DateTime.fromJSDate(selectedIteration?.updatedAt?.toDate())?.toFormat(
                    'LLL d, yyyy h:mm a'
                  ) || 'Loading...'}
                </div>
              </li>

              <li className="flex w-full justify-between gap-x-3 border-b border-zinc-100 pb-3 font-sans">
                <div className="flex space-x-2">
                  {statusIcon}
                  <div className="font-mono">Iteration status</div>
                </div>
                <div>
                  <Badge color={statusColor} className="font-mono">
                    {iterationStatus}
                  </Badge>
                </div>
              </li>
              {Boolean(vmStatus) && (
                <li className="flex w-full justify-between gap-x-3 border-b border-zinc-100 pb-3 font-sans">
                  <div className="flex space-x-2">
                    <MonitorDot className="h-6 w-5 flex-none text-zinc-700" aria-hidden="true" />
                    <div className="font-mono">VM Status</div>
                  </div>
                  <div className="flex items-center justify-center space-x-4">
                    <MStopVMButton
                      key={`${selectedIteration?.updatedAt}-${vmStatus}`}
                      iterationId={selectedIteration?.id}
                      status={vmStatus}
                      onVMStopped={handleVMStopped}
                    />

                    <Badge color="zinc" className="font-mono">
                      {vmStatus}
                    </Badge>
                  </div>
                </li>
              )}
            </ul>

            <div className="mt-10">
              <h5 className="text-sm font-semibold text-zinc-800">Instructions</h5>
              <p className="mt-2 whitespace-pre-line text-sm leading-7 text-gray-600">
                {selectedIteration?.prompt}
              </p>
              <div className="flex">
                <div className="mt-8 flex items-center">
                  <div className="">
                    <Button onClick={() => setOpen(true)}>Pick other iteration</Button>
                  </div>
                  <div className="ml-2 text-muted-foreground">
                    or{' '}
                    <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                      <span className="text-xs">⌘</span>J
                    </kbd>{' '}
                    to open picker
                  </div>
                </div>
                <div className="flex flex-1 items-end justify-end">
                  <div className=" flex items-center justify-center">
                    <Button
                      outline
                      onClick={() => {
                        setIsNewIterationDialogOpen(true)
                      }}
                      disabled={isNewIterationDialogOpen}
                      className={isNewIterationDialogOpen ? ' hover:cursor-wait' : ''}
                    >
                      {isNewIterationDialogOpen ? 'Working....' : 'Create new iteration'}
                    </Button>
                  </div>
                  <div className=" flex items-center justify-center">
                    <Button
                      outline
                      onClick={() => {
                        setIsCreateIterationDialogOpen(true)
                      }}
                      disabled={isCreateIterationDialogOpen}
                      className={isCreateIterationDialogOpen ? ' hover:cursor-wait' : ''}
                    >
                      {isCreateIterationDialogOpen ? 'Working....' : 'Create new iteration beta'}
                    </Button>
                  </div>

                  {selectedIteration?.projectId && (
                    <MNewIterationDialog
                      key={selectedIteration?.id + '-create-iteration-dialog'}
                      projectId={selectedIteration?.projectId}
                      isOpen={isNewIterationDialogOpen}
                      onClose={() => {
                        setIsNewIterationDialogOpen(false)
                      }}
                      sourceIterationData={selectedIteration || {}}
                      isFirstIterationForProject={false}
                      onIterationCreated={onIterationCreated}
                    />
                  )}
                  {selectedIteration?.projectId && (
                    <MCreateIterationDialog
                      key={selectedIteration?.id + '-create-iteration-dialog-beta'}
                      projectId={selectedIteration?.projectId}
                      isOpen={isCreateIterationDialogOpen}
                      onClose={() => {
                        setIsCreateIterationDialogOpen(false)
                      }}
                      sourceIterationData={selectedIteration || {}}
                      isFirstIterationForProject={false}
                      onIterationCreated={onIterationCreated}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-6 grid h-80 w-full grid-cols-2">
            <div className="flex h-full flex-col justify-between bg-white/60 p-8 ring-1 ring-gray-900/10 sm:mx-8 sm:p-10 lg:mx-0">
              <div className="flex flex-col">
                <h3 className="font-mono text-base font-bold text-zinc-900">Code</h3>

                <p className="mt-4 text-base text-gray-800">
                  Iteration code is pushed to the repository after each action
                </p>
              </div>

              <ul role="list" className="mt-8 cursor-pointer space-y-6 leading-6 sm:mt-10">
                {hasValidBranch(selectedIteration) && (
                  <li className="flex gap-x-3">
                    <GitBranch
                      className="size-5 flex-none text-zinc-800"
                      strokeWidth="3"
                      aria-hidden="true"
                    />
                    <Link
                      href={`${convertGitHubSSHToHTTPS(selectedIteration?.repoURI)}/tree/${selectedIteration?.branchName}`}
                      target={'_blank'}
                      className="text-base font-bold text-zinc-600 hover:text-zinc-800 hover:underline hover:underline-offset-4"
                      onClick={() => {
                        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO, {
                          iterationId: selectedIteration?.id,
                          projectId: selectedIteration?.projectId,
                          teamId: selectedIteration?.teamId,
                          organizationId: selectedIteration?.organizationId,
                          status: selectedIteration?.status,
                        })
                      }}
                    >
                      Code repository
                    </Link>
                  </li>
                )}
                {hasValidCommitHashes(selectedIteration) && (
                  <li className="flex gap-x-3">
                    <GitPullRequestArrow
                      className="size-5 flex-none text-zinc-800"
                      strokeWidth="3"
                      aria-hidden="true"
                    />
                    <Link
                      href={`${convertGitHubSSHToHTTPS(selectedIteration?.repoURI)}/compare/${selectedIteration?.startCommitHash}...${selectedIteration?.endCommitHash}`}
                      target={'_blank'}
                      className="text-base font-bold text-zinc-600 hover:text-zinc-800 hover:underline hover:underline-offset-4"
                      onClick={() => {
                        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES, {
                          iterationId: selectedIteration?.id,
                          projectId: selectedIteration?.projectId,
                          teamId: selectedIteration?.teamId,
                          organizationId: selectedIteration?.organizationId,
                          status: selectedIteration?.status,
                        })
                      }}
                    >
                      Code changes for iteration
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="flex h-full flex-col justify-between rounded-lg bg-white/60 p-8 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-t-none sm:p-10 lg:mx-0 lg:rounded-bl-none lg:rounded-tr-lg">
              <div className="flex flex-col">
                <h3 className="font-mono text-base font-bold text-zinc-900">Preview</h3>
                <p className="mt-4 text-base text-gray-800">
                  Once the preview is ready, you will be able to see the result of the iteration
                </p>
              </div>
              <ul role="list" className="mt-8 cursor-pointer space-y-6 leading-6 sm:mt-10">
                <li className="flex items-center  gap-x-3">
                  {previewUrl ? (
                    <>
                      <Eye
                        className="size-5 flex-none text-zinc-800"
                        strokeWidth="3"
                        aria-hidden="true"
                      />
                      <Link
                        href={previewUrl}
                        target={'_blank'}
                        className="text-base font-bold text-zinc-600 hover:text-zinc-800 hover:underline hover:underline-offset-4"
                        onClick={() => {
                          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_PREVIEW, {
                            iterationId: selectedIteration?.id,
                            projectId: selectedIteration?.projectId,
                            teamId: selectedIteration?.teamId,
                            organizationId: selectedIteration?.organizationId,
                            status: selectedIteration?.status,
                            vmStatus: selectedIteration?.vmStatus,
                          })
                        }}
                      >
                        Preview
                      </Link>
                    </>
                  ) : (
                    <>
                      <Eye
                        className="size-5 flex-none text-zinc-800"
                        strokeWidth="3"
                        aria-hidden="true"
                      />
                      <div
                        onClick={() => {
                          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_PREVIEW_NO_LINK, {
                            iterationId: selectedIteration?.id,
                            projectId: selectedIteration?.projectId,
                            teamId: selectedIteration?.teamId,
                            organizationId: selectedIteration?.organizationId,
                            status: selectedIteration?.status,
                            vmStatus: selectedIteration?.vmStatus,
                          })
                        }}
                        className="cursor-not-allowed text-base font-bold text-zinc-600 hover:text-zinc-800 "
                      >
                        Preview not yet available
                      </div>
                    </>
                  )}
                </li>
                {shouldShowLogsUrl && (
                  <li className="flex items-center  gap-x-3">
                    <Terminal
                      className="size-5 flex-none text-zinc-800"
                      strokeWidth="3"
                      aria-hidden="true"
                    />
                    <Link
                      href={logsUrl}
                      target={'_blank'}
                      className="text-base font-bold text-zinc-600 hover:text-zinc-800 hover:underline hover:underline-offset-4"
                    >
                      Logs
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox
                  onChange={item => {
                    handleIterationSelected(item)
                  }}
                >
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 size-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search by iteration id, creator id or part of prompt..."
                      onChange={event => setQuery(event.target.value)}
                      autoFocus
                    />
                  </div>

                  {filteredIterations.length > 0 && (
                    <Combobox.Options
                      static
                      className="max-h-[720px] scroll-pb-2 scroll-pt-11 space-y-2 overflow-y-auto pb-2"
                    >
                      {Object.entries(iterationsGroupedByDate).map(([category, items]) => (
                        <li key={category}>
                          <h2 className="bg-gray-100 px-4 py-2 font-mono text-xs font-semibold text-gray-900">
                            {category}
                          </h2>
                          <ul className="text-sm text-gray-800">
                            {items.map(item => (
                              <Combobox.Option
                                key={item.id}
                                value={item}
                                className={({ active }) =>
                                  classNames(
                                    'cursor-default select-none border-b border-zinc-200 px-4 text-base',
                                    active && 'bg-zinc-100',
                                    activeIterationId === item.id && 'bg-zinc-800  text-zinc-50'
                                  )
                                }
                              >
                                <div className="flex flex-col px-4 py-6">
                                  <div className="flex items-start justify-between">
                                    <div className="flex flex-col ">
                                      <span className="font-mono text-lg font-semibold tracking-tight">
                                        {item.id}
                                      </span>
                                      <span className="mt-2 font-mono text-sm text-gray-600">
                                        {getUrlFromText(item.prompt)?.map((url, index) => (
                                          <span
                                            key={index}
                                            className={classNames(
                                              activeIterationId === item.id
                                                ? 'text-zinc-50'
                                                : 'text-blue-500'
                                            )}
                                          >
                                            {url}
                                          </span>
                                        ))}
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                      <Badge
                                        color={activeIterationId === item.id ? 'white' : 'zinc'}
                                      >
                                        {item.status}
                                      </Badge>
                                    </div>
                                  </div>
                                  <table
                                    className={classNames(
                                      'mt-4 font-mono text-xs',
                                      activeIterationId === item.id
                                        ? 'text-zinc-50'
                                        : 'text-zinc-700'
                                    )}
                                  >
                                    <tr className="h-6">
                                      <td className="w-28">Created by </td>{' '}
                                      <td className="font-bold">{item.createdBy || 'N/A'}</td>
                                    </tr>
                                    <tr className="h-6">
                                      <td className="w-28">Created on </td>
                                      <td className="font-bold">
                                        {DateTime.fromJSDate(item.createdAt.toDate()).toFormat(
                                          'LLL d, yyyy h:mm a'
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="h-6">
                                      <td className="w-28">Last activity </td>
                                      <td className="font-bold">
                                        {DateTime.fromJSDate(item.updatedAt.toDate()).toFormat(
                                          'LLL d, yyyy h:mm a'
                                        )}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </Combobox.Option>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </Combobox.Options>
                  )}

                  {query !== '' && filteredIterations.length === 0 && (
                    <div className="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14">
                      <FaceFrownIcon className="mx-auto size-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">No iterations found</p>
                      <p className="mt-2 text-gray-500">
                        Search tries to match the iteration ID within this project. Try searching
                        for a different ID.
                      </p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {!selectedIteration?.isFeedbackCaptured && (
        <OIterationFeedbackDialog
          key={selectedIteration?.id}
          isOpen={showFeedbackDialog}
          onClose={handleDismissFeeedbackDialog}
          iterationId={selectedIteration?.id}
          projectId={selectedIteration?.projectId}
          teamId={selectedIteration?.teamId}
          organizationId={selectedIteration?.organizationId}
        />
      )}
      {shouldAllowFeedback && (
        <div className="fixed bottom-6 right-6 z-10 rounded-lg shadow-2xl drop-shadow-md">
          {/* <h5 className="">Thoughts on this iteration?</h5> */}
          <Button
            color="zinc"
            className="cursor-pointer"
            onClick={() => {
              setIterationFeedbackDismissed(false)
            }}
          >
            <div className="flex items-center p-2">
              <span className="mr-4 inline-block w-32 text-left">
                Are you happy with this iteration?
              </span>
              <span className="inline-block text-xl ">👍 / 👎</span>
            </div>
          </Button>
        </div>
      )}
    </>
  )
}

MIterationsPicker.propTypes = {
  iterations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      prompt: PropTypes.string,
      status: PropTypes.string,
      updatedAt: PropTypes.shape({
        toDate: PropTypes.func,
      }),
    })
  ),
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  activeIterationId: PropTypes.string,
  onActiveSelected: PropTypes.func,
  lastTick: PropTypes.number,
  onIterationCreated: PropTypes.func,
}

function getPreviewUrl(urls) {
  if (urls == null || urls.length === 0) {
    return null
  }

  if (urls.length === 1) {
    return urls[0]
  }

  for (let url of urls) {
    let port = new URL(url).port
    if (port !== '4000') {
      return url
    }
  }

  return null
}
