export const ROUTES = {
  ROOT: '/',
  HOME: '/home',
  LOGIN: '/login',
  ONBOARDING: '/onboarding',
  ONBOARDING_BUILDING: '/onboarding/building',
  PROJECTS: '/projects',
  SIGN_IN_WITH_LINK_CONFIRMATION: '/sign-in-with-email-link-confirmation/',
  PROJECT_DETAILS: '/projects/:projectId/*',
  // PROJECT_DETAILS_ITERATIONS: '/projects/:projectId/iterations',
  // PROJECT_DETAILS_HINTS: '/projects/:projectId/hints',
  ORGANIZATIONS: '/organizations',
  ORGANIZATION_DETAILS: '/organizations/:organizationId',
  TEAM_DETAILS: '/teams/:teamId',
  MANAGE_VMS: '/manage-vms',
  ITERATION_FEEDBACK: '/iteration-feedback',
  DASHBOARD: '/dashboard',
  USERS: '/users',
}
